import { faLocationDot, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import "react-toastify/dist/ReactToastify.css";
// import { TabPanel, useTabs } from "react-headless-tabs";
// import { TabSelector } from "../../components/TabSelector.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback } from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageViewer from "react-simple-image-viewer";
import "./ParticularProperty.css";
import { Carousel, CarouselItem, CarouselControl, Button } from "reactstrap";
import { useEffect } from "react";
import Map from "../../components/Map";
import Cookies from "js-cookie";
import axios from "../../api/axios";


// AdminDetailsModal Component
// const ReviewPropertyModal = ({ isOpen, toggle, adminId, propertyId }) => {
//   const [data, setData] = useState({ propertyId });

//   const onChange = (value, field) => {
//     setData({
//       ...data,
//       [field]: value,
//     });
//   };

//   const handle = async () => {
//     setData({ ...data, id: propertyId });
//     console.log(data);

//     try {
//       const res = await axios.post(`/report/report-action/${adminId}`, data);
//       console.log(res.data);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   return (
//     <Modal isOpen={isOpen} toggle={toggle}>
//       <ModalHeader>Review Reported Property</ModalHeader>
//       <ModalBody>
//         <label>Review : </label>
//         <input type="text" name="adminReview" onChange={onChange} />
//         <input type="radio" name="actionType" value={true} id="" /> Accept
//         <input type="radio" name="actionType" value={false} id="" /> Reject
//         <Button onClick={toggle}>Submit</Button>
//       </ModalBody>
//     </Modal>
//   );
// };

// const [showModal, setShowModal] = useState(false);

const ReportedProperty = () => {
  const [property, setProperty] = useState();
  const adminId = Cookies.get("id");
  const [reportData, setReportData] = useState({});
  const navigate = useNavigate();
  const [reviewData, setReviewData] = useState({
    id: "",
    adminReview: "",
    actionType: "",
  });

  const onChange = (e) => {
    setReviewData({
      ...reviewData,
      id: reportData.id,
      [e.target.name]: e.target.value,
    });
  };

  const handleReview = async () => {
    await setReviewData({ ...reviewData, id: id });
    // console.log(reviewData);

    if (reviewData.actionType === "" || reviewData.adminReview === "") {
      alert("Please fill out all fields!!!");
      return;
    }

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_IP}/report/report-action/${adminId}/dncbdj/dbchdcb`,
        reviewData
      );
      // console.log(res.data);
      alert("Reported Property Reviewed SuccessFully!!");
      navigate("/all-reported-properties");
    } catch (error) {
      // console.log(error);
    }
  };

  // const [selectedTab, setSelectedTab] = useTabs([
  //   "details",
  //   "amenities",
  //   "pricing",
  // ]);

  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  // const [reportedPropertyData, setReportedPropertyData] = useState({});

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const next = (length) => {
    if (animating) return;
    const nextIndex = activeIndex === length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = (length) => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const [showInstructions, setShowInstructions] = useState(false);

  const [address, setAddress] = useState([]);
  const { id } = useParams();
  // const [reportedPropertyData, setReportedPropertyData] = useState({});
  const [owner, setOwner] = useState({});
  const [user, setUser] = useState({});
  const [topic, setTopic] = useState("");

  useEffect(() => {
    // fetch(`http://15.206.66.176:8080/report/Reported-property/${id}`)
    //   .then((res) => res.json())
    //   .then((data) => {
    //     setProperty(data.property);
    //     setReportedPropertyData(data);
    //     console.log(reportedPropertyData);
    //     // console.log(data);
    //     const address = {
    //       coordinates: [
    //         parseFloat(data.property.address.latitude) || 23.0225,
    //         parseFloat(data.property.address.logitude) || 72.5714,
    //       ],
    //       label: data.title,
    //       link: `/particular-property/${data.id}`,
    //     };
    //     setAddress([address]);
    //   })
    //   .catch((err) => console.log(err));

    const fetchData = async (id) => {
      try {
        const res = await axios(`${process.env.REACT_APP_API_IP}/report/Reported-property/${id}/jidbcudh/cdhvcdh`);
        const data = res.data;
        setProperty(data.property);
        setOwner(data.owner);
        setUser(data.user);
        setTopic(data.reportTopic);
        setReportData(data);

        const address = {
          coordinates: [
            parseFloat(data.property.address.latitude) || 23.0225,
            parseFloat(data.property.address.logitude) || 72.5714,
          ],
          label: data.title,
          link: `/particular-property/${data.id}`,
        };

        setAddress([address]);
      } catch (error) {
        // console.log(error);
      }
    };

    fetchData(id);
    // eslint-disable-next-line
  }, [id]);

  if (!property) {
    return <p>Loading...</p>; // You can show a loading message or spinner
  }
  // if (!properties) {
  //   return <p>Loading...</p>; // You can show a loading message or spinner
  // }

  // const toggleAdminDetailsModal = () => {
  //   setShowAdminDetailsModal(!showAdminDetailsModal);
  // };

  return (
    <div className="">
      {/* <ReviewPropertyModal
        isOpen={showModal}
        toggle={toggleAdminDetailsModal}
        adminId={adminId}
        propertyId={property.id}
      /> */}

      <div className="container py-4">
        <div id="property-heading" className="row">
          <h2 id="addressHeading" className=" col-lg-8 col-sm-12">
            {property.title} {property.address.pincode}
          </h2>
        </div>
        <div id="propertyPhotos" className="row mobileAndTab-hide">
          <div className="col-md-9 col-8 " style={{ maxHeight: "500px" }}>
            <img
              src={property.photoUrls[0] || ""}
              onClick={() => openImageViewer(0)}
              alt="photos"
              width={"100%"}
              height={"500px"}
            />
          </div>
          <div
            className="col-md-3 col-4 "
            id="SecondaryImageBox"
            style={{ maxHeight: "500px" }}
          >
            <div className="mobileAndTab-hide ">
              <img
                src={property.photoUrls[1] || ""}
                onClick={() => openImageViewer(1)}
                alt="photos"
                width={"100%"}
                height={"100%"}
              />
            </div>
            <span className="my-2"></span>
            <div className="">
              <div
                style={{
                  position: "relative",
                  height: "100%",
                }}
              >
                <img
                  src={property.photoUrls[2]}
                  alt="property "
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  onClick={() => openImageViewer(2)}
                />
                {property.photoUrls.length > 3 && (
                  <div
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      color: "white", // Text color
                      fontSize: "24px", // Text font size
                    }}
                    onClick={() => openImageViewer(3)}
                  >
                    +{property.photoUrls.length - 3} More
                  </div>
                )}
              </div>
            </div>
          </div>

          {isViewerOpen && (
            <ImageViewer
              src={property.photoUrls}
              currentIndex={currentImage}
              onClose={closeImageViewer}
              disableScroll={true}
              backgroundStyle={{
                backgroundColor: "rgba(0,0,0,0.9)",
                height: "90%",
                top: "10%",
              }}
              closeOnClickOutside={true}
            />
          )}
        </div>
        <div className="LaptopHide">
          <Carousel
            activeIndex={activeIndex}
            next={() => {
              next(property.photoUrls.length);
            }}
            previous={() => {
              previous(property.photoUrls.length);
            }}
            id="ImageBox"
            interval={false}
          >
            {(property.photoUrls || []).map((item, index) => (
              <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={index} // Use the index as the key
              >
                <img
                  src={item}
                  alt="propertyImage"
                  id="CarouselImage"
                  className="rounded-2"
                />
              </CarouselItem>
            ))}
            <CarouselControl
              direction="prev"
              directionText="Previous"
              onClickHandler={previous}
            />
            <CarouselControl
              direction="next"
              directionText="Next"
              onClickHandler={next}
            />
          </Carousel>
        </div>
        <div id="propertyDetails" className="row">
          <div className="col-md-8">
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Property Details :</div>
              <div className="row innerDetailsBox">
                <div className="col-md">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Propery Type:</div>
                    <div className="valueTextForDetail">
                      {property.type}, {property.bedroom} Bedrooms,
                      {property.bathroom} Bathrooms
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Furnishing :</div>
                    <div className="valueTextForDetail">
                      {property.furnishedType}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">
                      No of People Currently Living:
                    </div>
                    <div className="valueTextForDetail">
                      {property.currentlyLiving}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">
                      Options Available In :
                    </div>
                    <div className="valueTextForDetail">{property.sharing}</div>
                  </div>
                </div>
                <div className="col-md">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Available From :</div>
                    <div className="valueTextForDetail">
                      {property.availableFrom}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Available For :</div>
                    <div className="valueTextForDetail">
                      {property.memberedAllowed}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">
                      No of Vacancy Available:
                    </div>
                    <div className="valueTextForDetail">
                      {property.vacancyAvailable}
                    </div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Non-Veg allowed :</div>
                    <div className="valueTextForDetail">
                      {property.nonVegAllowed}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Amenities :</div>
              <div className="innerDetailsBox d-flex flex-wrap" id="emnities">
                {property.amenities.includes("electricity") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Electricity.png"
                      alt="Amenities"
                    />
                    <span>Electricity</span>
                  </div>
                )}
                {property.amenities.includes("balcony") && (
                  <div className="AmenityBox col-3 col-lg-2">

                    <img
                      className=" amenities-size"
                      src="/emenities/balcony.png"
                      alt="Amenities"
                    />
                    <span>Balcony</span>


                  </div>
                )}
                {property.amenities.includes("tv") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/T.V.png"
                      alt="Amenities"
                    />
                    <span>T.V.</span>
                  </div>
                )}
                {property.amenities.includes("2-wheeler-parking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Parking.png"
                      alt="Amenities"
                    />
                    <span>Parking</span>
                  </div>
                )}
                {property.amenities.includes("free-wifi") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Free Wifi.png"
                      alt="Amenities"
                    />
                    <span>Free Wifi</span>
                  </div>
                )}
                {property.amenities.includes("cooking") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Cooking.png"
                      alt="Amenities"
                    />
                    <span>Cooking</span>
                  </div>
                )}
                {property.amenities.includes("laundry") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Laundry.png"
                      alt="Amenities"
                    />
                    <span>Laundry</span>
                  </div>
                )}
                {property.amenities.includes("fridge") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Fridge.png"
                      alt="Amenities"
                    />
                    <span>Fridge</span>
                  </div>
                )}
                {property.amenities.includes("ro-water") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/RO Water.png"
                      alt="Amenities"
                    />
                    <span>R.O. Water</span>
                  </div>
                )}
                {property.amenities.includes("24*7-water") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Water.png"
                      alt="Amenities"
                    />
                    <span>24*7 Water</span>
                  </div>
                )}
                {property.amenities.includes("air-conditioner") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/AC.png"
                      alt="Amenities"
                    />
                    <span>A.C.</span>
                  </div>
                )}
                {property.amenities.includes("breakfast") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Breakfast.png"
                      alt="Amenities"
                    />
                    <span>Breakfast</span>
                  </div>
                )}
                {property.amenities.includes("gyser") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Gyser.png"
                      alt="Amenities"
                    />
                    <span>Gyser</span>
                  </div>
                )}
                {property.amenities.includes("lunch") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Lunch.png"
                      alt="Amenities"
                    />
                    <span>Lunch</span>
                  </div>
                )}
                {property.amenities.includes("security") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Security.png"
                      alt="Amenities"
                    />
                    <span>Security</span>
                  </div>
                )}
                {property.amenities.includes("microwave") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Microwave.png"
                      alt="Amenities"
                    />
                    <span>Microwave</span>
                  </div>
                )}
                {property.amenities.includes("fans") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Fen.png"
                      alt="Amenities"
                    />
                    <span>Fans</span>
                  </div>
                )}
                {property.amenities.includes("wardrobe") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/Wardrobe.png"
                      alt="Amenities"
                    />
                    <span>Wardrobe</span>
                  </div>
                )}
                {property.amenities.includes("cctv") && (
                  <div className="AmenityBox col-3 col-lg-2">
                    <img
                      className="amenities-size"
                      src="/emenities/CCTV.png"
                      alt="Amenities"
                    />
                    <span>C.C.T.V.</span>
                  </div>
                )}
                {property.amenities.includes("house-keeping") && (
                  <div className="AmenityBox col-4 d-lg-none">
                    <img
                      className="amenities-size"
                      src="/emenities/Housekeeping.png"
                      alt="Amenities"
                    />
                    <span>House Keeping</span>
                  </div>
                )}
              </div>
            </div>
            {property.instructions && (
              <>
                {" "}
                <div className="detailsBox mobileAndTab-hide">
                  <div className="boxHeader">
                    {" "}
                    Instructions / rules or regulations:
                  </div>
                  <div className="innerDetailsBox grey">
                    {property.instructions}
                  </div>
                </div>
                <div className="detailsBox LaptopHide">
                  <span
                    className="text-primary"
                    role="button"
                    onClick={() => {
                      setShowInstructions(!showInstructions);
                    }}
                  >
                    {showInstructions ? (
                      <div className="grey p-3">
                        {" "}
                        Instructions / rules or regulations{" "}
                        <FontAwesomeIcon icon={faAngleDown} />
                      </div>
                    ) : (
                      <div className="grey p-3">
                        {" "}
                        Instructions / rules or regulations{" "}
                        <FontAwesomeIcon icon={faAngleDown} />
                      </div>
                    )}
                  </span>
                  {showInstructions && (
                    <div className="innerDetailsBox grey">
                      {property.instructions}
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-md-4">
            <div className="detailsBox">
              <div className="boxHeader"> Address:</div>
              <div className="innerDetailsBox">
                <div className="">
                  {" "}
                  <FontAwesomeIcon className="grey" icon={faLocationDot} />{" "}
                  {property.address.houseno}, {property.address.streetAddress}{" "}
                  {property.address.area} {property.address.pincode}{" "}
                </div>
                <div className="mapBox mt-3">
                  <Map addresses={address} height={"300px"} />
                </div>
              </div>
            </div>
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Pricing :</div>
              <div className="detailsBox ">
                <div className="innerDetailsBox">
                  <div className="bg-white rounded-2 my-1">
                    <div className="row align-items-center">
                      <div className="col-6 pricingLabel ">
                        {" "}
                        Rent (Per Month)
                      </div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail  fw-bold  col-3">
                        {" "}
                        {property.type === "private flat"
                                  ? `₹ ${property.totalFlatRent}`
                                  : property.sharingType.singleRent
                                  ? `₹ ${property.sharingType.singleRent}`
                                  : property.sharingType.doubleRent
                                  ? `₹ ${property.sharingType.doubleRent}`
                                  : property.sharingType.tripleRent
                                  ? `₹ ${property.sharingType.tripleRent}`
                                  : `₹ ${property.rentEachHead}`}
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-2 my-1">
                    <div className="row align-items-center">
                      <div className="col-6 pricingLabel">
                        {" "}
                        Deposit (in months)
                      </div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fw-bold  col-3">
                        {property.depoist} rent
                      </div>
                    </div>
                  </div>
                  <div className="bg-white rounded-2 my-2">
                    <div className="row align-items-center">
                      <div className="col-6 grey fs-6 pricingLabel">
                        {" "}
                        Additional Cost
                      </div>
                      <div className="col-1">:</div>
                      <div className="valueTextForDetail fs-6 grey col-3">
                        {" "}
                        {property.additionalCosts.cost1 ? "Yes" : "No"}
                      </div>
                    </div>
                    {property.additionalCosts.description1 &&
                      property.additionalCosts.cost1 && (
                        <div className="row align-items-center">
                          <div className="col-6  pricingLabel">
                            {" "}
                            {property.additionalCosts.description1}
                            {/* {console.log(property.additionalCosts.description1)} */}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail  col-3">
                            {" "}
                            {property.additionalCosts.cost1}
                          </div>
                        </div>
                      )}
                    {property.additionalCosts.description2 &&
                      property.additionalCosts.cost2 && (
                        <div className="row align-items-center">
                          <div className="col-6  pricingLabel">
                            {" "}
                            {property.additionalCosts.description2}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail  col-3">
                            {" "}
                            {property.additionalCosts.cost2}
                          </div>
                        </div>
                      )}
                    {property.additionalCosts.description3 &&
                      property.additionalCosts.cost3 && (
                        <div className="row align-items-center">
                          <div className="col-6  pricingLabel">
                            {" "}
                            {property.additionalCosts.description3}
                          </div>
                          <div className="col-1">:</div>
                          <div className="valueTextForDetail  col-3">
                            {" "}
                            {property.additionalCosts.cost3}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="propertyDetails" className="row" style={{ width: "800px" }}>
          <div className="col-md-8">
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> Owner Details :</div>
              <div className="row innerDetailsBox">
                <div className="col-md">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Owner Name:</div>
                    <div className="valueTextForDetail">{owner.name}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Owner ID :</div>
                    <div className="valueTextForDetail">{owner.id}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">Owner Email</div>
                    <div className="valueTextForDetail">{owner.email}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">
                      Owner Mobile Number
                    </div>
                    <div className="valueTextForDetail">{owner.number}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="propertyDetails" className="row" style={{ width: "800px" }}>
          <div className="col-md-8">
            <div className="detailsBox mobileAndTab-hide">
              <div className="boxHeader"> User Details :</div>
              <div className="row innerDetailsBox">
                <div className="col-md">
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">User Name:</div>
                    <div className="valueTextForDetail">{user.name}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">User ID :</div>
                    <div className="valueTextForDetail">{user.id}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">User Email</div>
                    <div className="valueTextForDetail">{user.email}</div>
                  </div>
                  <div className="SingleDetailBox">
                    <div className="labelTextForDetail">User Mobile Number</div>
                    <div className="valueTextForDetail">{user.number}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <p>Reason for report : {topic}</p>

        <div className="review-form">
          {/* <Button onClick={() => setShowModal(true)}>Open</Button> */}
          <label>Review : </label>
          <input
            type="text"
            name="adminReview"
            className="review-input"
            onChange={onChange}
          />
          <div>
            <input
              type="radio"
              name="actionType"
              value={true}
              id="acceptRadio"
              onChange={onChange}
              className="radio-input"
            />
            <label htmlFor="acceptRadio">Accept</label>
            <input
              type="radio"
              name="actionType"
              value={false}
              id="rejectRadio"
              onChange={onChange}
              className="radio-input"
            />
            <label htmlFor="rejectRadio">Reject</label>
          </div>
          <Button onClick={handleReview} className="submit-button">
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReportedProperty;
