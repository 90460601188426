import React, { useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./ListProperty.css"
import Step2 from "./ListProperty/step2.js"
import Step3 from "./ListProperty/step3.js";
import Step4 from "./ListProperty/step4.js";
// import LoginModal from "./Login.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import { Image } from "react-bootstrap";
// import { Link } from "react-router-dom";
const ListProperty = ({ user }) => {
  const Navigate = useNavigate();

  const [currentStep, setCurrentStep] = useState(1);
  // const [isVerified, setisVerified] = useState();
  // const [loginmodal, setLoginmodal] = useState(false);
  const [signinmodal, setSigninmodal] = useState(false);
  // const signinModal = () => setSigninmodal(!signinmodal);

  const signinModal = () => {
    if (!user) {
      Navigate("/"); // Navigate to the home page when closing the modal without logging in
    } else {
      setSigninmodal(false);
    }
  };

  const todayDate = new Date().toISOString().substr(0, 10);

  const initialFlatSeekerDetails = {
    ownerId: "",
    name: "",
    number: "",
    type: "",
    flatType: "",
    preference: [],
    area: [],
    city: "",
    budget: "",
    furnishedType: [],
    occupancyType: [],
    profilePhoto: "",
    noOfPerson: 0,
    shiftingDate: "",
    gender: "",
    callAllowed: false,
    description: "",
  };

  const initialPropertyDetails = {
    ownerId: "",
    name: "",
    phone: "",
    tenantNumber: "",
    type: "",
    subtype: "",
    bedroom: "",
    furnishedType: "",
    amenities: [],
    memberedAllowed: "",
    nonVegAllowed: false,
    instructions: "",
    availableFrom: todayDate,
    washroomAttached: false,
    preference: [],
    sharingType: {
      singleSharing: "single",
      singleRent: "",
      doubleSharing: "double",
      doubleRent: "",
      tripleSharing: "triple",
      tripleRent: "",
    },
    totalFlatRent: "",
    isNegotiable: true,
    deposit: "",
    noticePeriod: 1,
    address: {
      houseno: "",
      area: "",
      pincode: "",
      streetAddress: "",
      city: "",
    },
    description: "",
    callAllowed: true,
  };

  const [flatSeekerDetails, setFlatSeekerDetails] = useState(initialFlatSeekerDetails);
  const [propertyDetails, setPropertyDetails] = useState(initialPropertyDetails);
  const resetDetails = (ownerId, type, name, number) => {
    setFlatSeekerDetails({
      ...initialFlatSeekerDetails,
      ownerId,
      type,
      name,
      number,
    });

    setPropertyDetails({
      ...initialPropertyDetails,
      ownerId,
      type,
      name,
      phone: number,
    });
  };

 

  const updateFlatSeekerDetails = (newDetails) => {
    setFlatSeekerDetails(newDetails);
  };

  const updatePropertyDetails = (newDetails) => {
    setPropertyDetails(newDetails);
  };

  const onChange = (value, field) => {
    if (field === "type") {
      resetDetails(propertyDetails.ownerId, value, propertyDetails.name, propertyDetails.phone);
    } else {
      setPropertyDetails((prevDetails) => ({
        ...prevDetails,
        [field]: value,
      }));
    }

    if (errors.name === field) {
      setErrors({
        name: "",
        error: "",
      });
    }
  };

  const handleInputChangeFlat = (e) => {
    const { name, value } = e.target;
  
    // Define the maximum length for fields that need restriction
    const maxLengthMap = {
      number: 10,
      // Add more fields as needed
    };
  
    // Check if the field needs length restriction
    if (maxLengthMap.hasOwnProperty(name) && value.length > maxLengthMap[name]) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[name]);
  
      // Update the state with the truncated value
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: truncatedValue,
      });
    } else {
      // For fields without length restriction, update the state normally
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: value,
      });
    }
  };  

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Define the maximum length for fields that need restriction
    const maxLengthMap = {
      rent: 6,
      deposit: 6,
      totalFlatRent: 6,
      // Add more fields as needed
    };

    // Define the regular expression patterns for fields that require specific validation
    const validationRules = {
      description: /^[a-zA-Z.,\s]*$/, // Allow only letters, spaces, dots, and commas
      instructions: /^[a-zA-Z.,\s]*$/,

      // Add more fields with their respective regex patterns as needed
    };

    // Check if the field needs length restriction
    if (
      maxLengthMap.hasOwnProperty(name) &&
      value.length > maxLengthMap[name]
    ) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[name]);

      // Update the state with the truncated value
      setPropertyDetails({
        ...propertyDetails,
        [name]: truncatedValue,
      });
      setFlatSeekerDetails({
        ...flatSeekerDetails,
        [name]: truncatedValue,
      })
    }
    // Check if the field requires specific validation
    else if (validationRules.hasOwnProperty(name)) {
      const regex = validationRules[name];

      // Check if the input matches the regex pattern or is an empty string
      if (regex.test(value) || value === "") {
        // If the input is valid, update the state
        setPropertyDetails({
          ...propertyDetails,
          [name]: value,
        });
        setFlatSeekerDetails({
         ...flatSeekerDetails,
          [name]: value,
        });
      }
    }
    // For fields without specific validation rules or length restriction, update the state normally
    else {
      setPropertyDetails({
        ...propertyDetails,
        [name]: value,
      });
      setFlatSeekerDetails({
       ...flatSeekerDetails,
        [name]: value,
      });
    }
  };

  const radioButtons = [
    { value: "pg", label: "PG" },
    { value: "sharing flat", label: "Sharing Flat" },
    { value: "private flat", label: "Private Flat" },
    { value: "flatseeker", label: "Flatseeker" },
  ];

  const [errors, setErrors] = useState({
    name: "",
    error: "",
  });
  const validateForm = () => {
    const newErrors = {};
    if (!propertyDetails.phone) {
      newErrors.name = "phone";
      newErrors.error = "Phone number is required.";
    } else if (propertyDetails.phone.length !== 10) {
      newErrors.name = "phone";
      newErrors.error = "Phone number must be 10 digits.";
    }
    if (!propertyDetails.name) {
      newErrors.name = "name";
      newErrors.error = "Owner name is required.";
    }

    if (!propertyDetails.type) {
      newErrors.name = "type";
      newErrors.error = "Please select a category.";
    }
    if (
      propertyDetails.type === "sharing flat" &&
      !propertyDetails.tenantNumber
    ) {
      newErrors.name = "tenantNumber";
      newErrors.error = "Please Enter Tenant number.";
    }
    if (
      propertyDetails.type === "sharing flat" &&
      propertyDetails.tenantNumber.length !== 10
    ) {
      newErrors.name = "tenantNumber";
      newErrors.error = "Tenant number must be 10 digits.";
    }
    // if (user.id == null) {
    //   newErrors.name = "id";
    //   newErrors.error = "Please signin again";
    // }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if there are no errors
  };

  const handleNextClick = async () => {
    const isValid = validateForm();
    if (isValid) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleSharingRentChange = (value, type) => {
    const maxLengthMap = {
      single: 6,
      double: 6,
      triple: 6,
    };

    // Check if the field needs length restriction
    if (
      maxLengthMap.hasOwnProperty(type) &&
      value.length > maxLengthMap[type]
    ) {
      // Truncate the value to the maximum length
      const truncatedValue = value.slice(0, maxLengthMap[type]);

      // Update the state with the truncated value
      setPropertyDetails({
        ...propertyDetails,
        sharingType: {
          ...propertyDetails.sharingType,
          [type]: truncatedValue,
        },
      });
    } else {
      // If the field does not need length restriction, update the state normally
      setPropertyDetails({
        ...propertyDetails,
        rentEachHead: {
          ...propertyDetails.rentEachHead,
          [type]: value,
        },
      });
    }
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadType, setUploadType] = useState("");

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleBulkListingClick = async () => {
    if (!selectedFile) {
      alert("Please select a file to upload");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    let url = "";
    if (uploadType === "application") {
      url = "/app/upload-file/mix";
    } else if (uploadType === "premium") {
      url = "https://premium.citynect.in/user/upload";
    } else {
      alert("Invalid upload type");
      return;
    }

    try {
      const response = await fetch(url, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        alert("File uploaded successfully");
      } else {
        alert("File upload failed");
      }
    } catch (error) {
      alert("An error occurred while uploading the file");
      // console.error(error);
    }
  };
  return (
    <div className="MainForListProperty" style={{ overflowX: "hidden" }}>
      {currentStep === 1 && (
        <div className="row justify-content-center">
          
          <div className="col-lg-5 list-margin-top mb-3 mx-2" id="formBox">
            <h3 className="mb-4 text-center">Let's start</h3>
            <Form>
              <FormGroup>
                <Label id="labelforMobileView" style={{ fontWeight: "500" }}>
                  Select category :
                </Label>
                <div className="radio-groups">
                  {radioButtons.map((button) => {
                    return (
                      <div
                        key={button.value}
                        className={`radio-button rounded-pill ${
                          propertyDetails.type === button.value
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => onChange(button.value, "type")}
                      >
                        {button.label}
                      </div>
                    );
                  })}
                </div>
                {errors.name === "type" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Label id="labelforMobileView"> Owner Details :</Label>
                <Input
                  type="text"
                  value={propertyDetails.name}
                  onChange={handleInputChange}
                  name="name"
                  id="name"
                  placeholder="Owner Name"
                />
                {errors.name === "name" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>

              {propertyDetails.type === "sharing flat" && (
                <FormGroup>
                  <Input
                    type="number"
                    value={propertyDetails.tenantNumber}
                    onChange={handleInputChange}
                    name="tenantNumber"
                    id="tenantNumber"
                    placeholder="Tenant Number"
                    maxLength={10}
                    minLength={10}
                  />
                  {/* to add icons before this  */}
                  {errors.name === "tenantNumber" && (
                    <div className="error-message text-danger">
                      {errors.error}
                    </div>
                  )}
                </FormGroup>
              )}
              <FormGroup>
                <Input
                  type="number"
                  value={propertyDetails.phone}
                  onChange={handleInputChange}
                  name="phone"
                  id="phone"
                  placeholder="Owner Phone Number"
                  maxLength={10}
                  minLength={10}
                />
                {/* to add icons before this  */}
                {errors.name === "phone" && (
                  <div className="error-message text-danger">
                    {errors.error}
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <Button
                  style={{
                    width: "100%",
                    backgroundColor: "#287DFD",
                    border: "none",
                    height: "40px",
                  }}
                  onClick={handleNextClick}
                >
                  Next <FontAwesomeIcon icon={faArrowRight} />
                </Button>
              </FormGroup>
            </Form>
          </div>
          <div className="col-lg-5 list-margin-top mb-3 mx-2" id="formBox">
          {currentStep === 1 && (
          <>
          <div className="d-flex flex-column align-items-center justify-content-center bg-white rounded" id="bulkListingForApplication">
            <h3>Bulk Listing</h3>
            <div className="bulkListingContainer mt-2 p-4 rounded" style={{ width: "100%", backgroundColor: "#bcd5fb" }}>
              <h3>Bulk Listing for Application</h3>
              <p>Upload an Excel sheet for bulk listing</p>
              <FormGroup>
                <Input
                  type="file"
                  name="excelFileApplication"
                  id="excelFileApplication"
                  accept=".xlsx,.xls"
                  onChange={(e) => {
                    handleFileChange(e);
                    setUploadType("application");
                  }}
                />
              </FormGroup>
              <Button
                style={{ width: "100%", backgroundColor: "#287DFD", border: "none", height: "40px" }}
                onClick={handleBulkListingClick}
              >
                Bulk Listing
              </Button>
            </div>
            <div className="bulkListingContainer mt-4 bg-warning p-4 rounded" style={{ width: "100%" }}>
              <h3>Bulk Listing for Premium Property</h3>
              <p>Upload an Excel sheet for bulk listing for Premium properties</p>
              <FormGroup>
                <Input
                  type="file"
                  name="excelFilePremiumProperty"
                  id="excelFilePremiumProperty"
                  accept=".xlsx,.xls"
                  onChange={(e) => {
                    handleFileChange(e);
                    setUploadType("premium");
                  }}
                />
              </FormGroup>
              <Button
                style={{ width: "100%", backgroundColor: "#287DFD", border: "none", height: "40px" }}
                onClick={handleBulkListingClick}
              >
                Bulk Listing
              </Button>
            </div>
          </div>
        </>
        
        )}
    
            </div>
         
        </div>
      )}

      {currentStep === 2 && propertyDetails.type !== "flatseeker" && (
        <Step2
          propertyDetails={propertyDetails}
          updatePropertyDetails={updatePropertyDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChange}
          setPropertyDetails={setPropertyDetails}
          handleSharingRentChange={handleSharingRentChange} // Add this line
        />
      )}
      {currentStep === 2 && propertyDetails.type === "flatseeker" && (
        <Step3
          flatSeekerDetails={flatSeekerDetails}
          propertyDetails={propertyDetails}
          updateFlatSeekerDetails={updateFlatSeekerDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChangeFlat}
        />
      )}
      {currentStep === 3 &&  (
        <Step4
          propertyDetails={propertyDetails}
          updatePropertyDetails={updatePropertyDetails}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          handleInputChange={handleInputChange}
        />
      )}
      {/* <LoginModal isOpen={loginmodal} onClose={loginModal} /> */}
      {/* <Signup isOpen={signinmodal} onClose={signinModal} /> */}

      
    </div>
  );
};

export default ListProperty;
