import "./payment-details.css";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { Link } from "react-router-dom";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";

const PaymentDetails = () => {
  const [data, setData] = useState([]);
  const [refundData, setRefundData] = useState({
    reason: "",
    application: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios("/api/v1/paymentGateway/payment-details");
        // setData(res.data);
        const filteredData = res.data.filter(
          (item) =>
            item.paymentStatus === "Success" ||
            item.paymentStatus === "Refund" ||
            item.paymentStatus === "Expired"
        );
        setData(filteredData);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChange = (e) => {
    setRefundData({
      ...refundData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async (event, orderId) => {
    const button = event.currentTarget;
    button.disabled = true;
    alert(orderId);
    // console.log(refundData);

    try {
      // Call API
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <div className="App">
      <h1>Payment Details</h1>
      <br />
      <br />
      <table>
        <thead>
          <tr>
            <th>Index No.</th>
            <th>Customer ID</th>
            <th>Amount</th>
            <th>Plan</th>
            <th>Order ID</th>
            <th>Status</th>
            <th>Created On</th>
            <th>Exp Date</th>
            <th>Details</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.customerId ? item.customerId : "-"}</td>
              <td>{item.amount ? item.amount : "-"}</td>
              <td>{item.plan ? item.plan : "-"}</td>
              <td>{item.orderId ? item.orderId : "-"}</td>
              <td style={{ color: "green" }}>
                {item.paymentStatus ? item.paymentStatus : "-"}
                <Button id="PopoverLegacy" type="button">
                  Refund
                </Button>
                <UncontrolledPopover
                  placement="bottom"
                  target="PopoverLegacy"
                  trigger="legacy"
                >
                  <PopoverHeader>Refund</PopoverHeader>
                  <PopoverBody>
                    <label>Reason: </label>
                    <input name="reason" onChange={handleChange} />
                    <br />
                    <br />
                    <label>Application: </label>
                    <input
                      style={{ width: "60%" }}
                      name="application"
                      onChange={handleChange}
                    />
                    <br />
                    <br />
                    <Button onClick={(e) => handleClick(e, item.orderId)}>
                      Submit
                    </Button>
                  </PopoverBody>
                </UncontrolledPopover>
              </td>
              <td>{item.createdOn ? new Date(item.createdOn).toLocaleDateString('en-GB') : "-"}</td>

              <td>{item.expiredDate ? item.expiredDate : "-"}</td>
              <td>
                <Link to={`/particular-payment-details?id=${item.customerId}`}>
                  Details
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentDetails;
