import "./UnverifiedProperties.css";
import { React, useEffect, useState } from "react";
import CustomCarousel from "../../components/CustomCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import NearbyLocations from "./NearbyLocations.jsx";
import MultiRangeSlider from "multi-range-slider-react";

import {
  faLocationDot,
  faArrowRight,
  faArrowLeft,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";

import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from "reactstrap";
import axios from "../../api/axios.js";
const adminId = Cookies.get("id");
const AllDeletedProperties = () => {
  const [superpassword, setSuperpassword] = useState("1");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filterType, setFilterType] = useState("all");
  const [propertyCount, setPropertyCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [propertyIdToDelete, setPropertyIdToDelete] = useState(null);
  const [propData, setPropData] = useState([]);

  const [all, setAll] = useState(true);
  const [rented, setRented] = useState(false);
  const [deleted, setDeleted] = useState(false);
  // const [unverified, setUnverified] = useState(false);
  const [filterBar, setFilterBar] = useState();
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
    "65be1ac595dac22ebe9f9b88",
  ];
  const [locationFilter, setLocationFilter] = useState("");
  const [cityFilter, setcityFilter] = useState("");
  const [city, setcity] = useState("");
  const [minValue, set_minValue] = useState(2000);
  const [maxValue, set_maxValue] = useState(50000);
  const Occupancy = [
    { value: "Single", label: "Single" },
    { value: "Double", label: "Double" },
    { value: "Triple", label: "Triple" },
  ];
  const filtertype = [
    { value: "pg", label: "PG" },
    { value: "private flat", label: "Private flat" },
    { value: "sharing flat", label: "Sharing flat" },
  ];
  const filtersubtype = [
    { value: "Independent House/Villa", label: "Independent House/Villa" },
    { value: "Flat", label: "Flat" },
    { value: "Rawhouse", label: "Rawhouse" },
    { value: "Studio apartment", label: "Studio apartment" },
  ];
  const [preferencesFilter, setPreferencesFilter] = useState([]);

  const Preferences = [
    { value: "Vegetarian", label: "Vegetarian" },
    { value: "Night Owl ", label: "Night Owl " },
    { value: "Early Riser", label: "Early Riser" },
    { value: "Foodie", label: "Foodie" },
    { value: "Social Butterfly", label: "Social Butterfly" },
    { value: "Alcohol Enthusiast", label: "Alcohol Enthusiast" },
    { value: "Pet Lover", label: "Pet Lover" },
    { value: "Health Conscious", label: "Health Conscious" },
    { value: "Adventure Seeker", label: "Adventure Seeker" },
    { value: "Smoking", label: "Smoking" },
  ];
  const [BedroomFilter, setBedroomFilter] = useState([]);

  const Bedroom = [
    { value: "1 RK", label: "1 RK" },
    { value: "1 BHK", label: "1 BHK" },
    { value: "2 BHK", label: "2 BHK" },
    { value: "3 BHK", label: "3 BHK" },
    { value: "4 BHK", label: "4 BHK" },
    { value: "5 BHK", label: "5 BHK" },
  ];
  const gender = [
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "", label: "Both" },
  ];
  const [type, settype] = useState("");
  const [subtype, setsubtype] = useState([]);

  const [occupancyFilter, setOccupancyFilter] = useState(
    Occupancy.map((occupancy) => ({ ...occupancy, isSelected: false }))
  );

  const [furnishedFilter, setFurnishedFilter] = useState([]);
  const [availabilityFilter, setAvailabilityFilter] = useState("");
  const [preference, setpreference] = useState("");
  const [preferencefilter, setpreferencefilter] = useState("");
  const [area, setarea] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [genderFilter, setGenderFilter] = useState("");
  const Furnished = [
    { value: "fully-furnished", label: "Furnished" },
    { value: "unfurnished", label: "Unfurnished" },
    { value: "semi-furnished", label: "Semi-furnished" },
  ];
  const [shiftingDate, setShiftingDate] = useState("");

  const [inputValue, setInputValue] = useState(''); 

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleSearchClick = () => {
    setSearchQuery(inputValue);
    applyFilters(); // Call applyFilters to perform the search with the current query
  };


  const handleInput = (e) => {
    set_minValue(e.minValue);
    set_maxValue(e.maxValue);
  };
  const handleGenderChange = (value) => {
    setGenderFilter(value);
  };
  const handleFurnishedChange = (value) => {
    if (furnishedFilter.includes(value)) {
      setFurnishedFilter(furnishedFilter.filter(item => item !== value));
    } else {
      setFurnishedFilter([...furnishedFilter, value]);
    }
  };

  const handleBedroomChange = (value) => {
    if (BedroomFilter.includes(value)) {
      setBedroomFilter(BedroomFilter.filter(item => item !== value));
    } else {
      setBedroomFilter([...BedroomFilter, value]);
    }
  };
  
  const handleLocationChange = (value) => {
    setLocationFilter(value);
  };
  const handleareaChange = (value) => {
    setarea([...area, value]);
  };
  const removearea = (items) => {
    const data = area.filter((item) => items != item);
    setarea(data);
  };
  const handleOccupancyChange = (index) => {
    setOccupancyFilter((prevFilter) => {
      const newFilter = [...prevFilter];
      newFilter[index].isSelected = !newFilter[index].isSelected;
      return newFilter;
    });
  };

  const handletypeChange = (value) => {
    settype(value);
  };
  const handlesubtypeChange = (value) => {
    if (subtype.includes(value)) {
      setsubtype(subtype.filter((item) => item !== value));
    } else {
      setsubtype([...subtype, value]);
    }
  };
const [loading, setLoading] = useState(false);


  const applyFilters = async (currentpg) => {
    setLoading(true); // Start loading
    if (!currentpg) {
      setCurrentPage(0);
    }
    let page = currentpg || 0;
    let value = {
      userId: "",
      search: searchQuery && searchQuery,
      type: type !== "" ? type : "",
      city: locationFilter !== "" ? locationFilter && locationFilter : "",
      areas: area !== "" ? area && area : [],
      subtype: subtype.length ? subtype : [],
      bedroom: BedroomFilter !== "" ? BedroomFilter : [],
      furnishedType: furnishedFilter !== "" ? furnishedFilter : [],
      availableFrom: shiftingDate !== "" ? shiftingDate : "",
      occupancyType:
        occupancyFilter && occupancyFilter.length > 0
          ? occupancyFilter
              .filter((property) => property.isSelected === true)
              .map((item) => item.value)
          : [],
      gender: genderFilter !== "" ? genderFilter : "",
      minRent: minValue,
      maxRent: maxValue,
      preference: preferencesFilter.length ? preferencesFilter : [],
    };
    let headers = {
      "Content-Type": "application/json",
    };
    let apiUrl = `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/deleted-properties/cijdbudbijsch/filter?page=${page}&size=10`;
    
    try {
      const data = await axios.post(apiUrl, value, headers);
      setFilteredProperties(data.data.data.properties);
      setPropData(data.data.data.properties);
      setPropertyCount(data.data.data.totalItems);
    } catch (error) {
      console.error("Error fetching filtered properties:", error);
      // Optionally, set an error state to display an error message to the user
      setError(
        "An error occurred while fetching properties. Please try again later."
      );
    } finally {
      setLoading(false); // End loading
    }
  };
  
  const goToPreviousPage = () => {
    if (currentPage > 0) {
      setLoading(true); // Start loading
      setCurrentPage(currentPage - 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
      applyFilters(currentPage - 1).finally(() => setLoading(false)); // End loading after filters are applied
    }
  };
  
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setLoading(true); // Start loading
      setCurrentPage(currentPage + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
      applyFilters(currentPage + 1).finally(() => setLoading(false)); // End loading after filters are applied
    }
  };
  

  const handlePreferencesChange = (value) => {
    if (preferencesFilter.includes(value)) {
      setPreferencesFilter(preferencesFilter.filter((item) => item !== value));
    } else {
      setPreferencesFilter([...preferencesFilter, value]);
    }
  };

  const resetfilter = () => {
    setcity("");
    setarea("");
    settype("");
    setSearchQuery("");
    setsubtype("");
    setBedroomFilter("");
    setFurnishedFilter("");
    setFurnishedFilter("");
    setAvailabilityFilter("");
    setOccupancyFilter(
      Occupancy.map((occupancy) => ({ ...occupancy, isSelected: false }))
    );
    setGenderFilter("");
    setPreferencesFilter("");
    setLocationFilter("");
    applyFilters();
    //
  };
  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };
  useEffect(() => {
    // This effect will run when the component mounts and anytime adminId changes
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId]);

  useEffect(() => {
    applyFilters();
  }, [
    locationFilter,
    area,
    occupancyFilter,
    subtype,
    preferencesFilter,
    BedroomFilter,
    minValue,
    maxValue,
    genderFilter,
    furnishedFilter,
    shiftingDate,
    deleted,
    type,
    rented,
    searchQuery,
  ]);

  const handleToggle = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/property/toggleStatus/${adminId}/${id}`
    );
    if (response.ok) {
      window.location.reload();
    } else {
      // console.log(response);
    }
  };

  

  let totalPages = parseInt(propertyCount / 10);
  let visibleData = filteredProperties;
  if (filteredProperties.length && filteredProperties.length > 10) {
    totalPages = Math.ceil(filteredProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = filteredProperties.slice(startIndex, endIndex);
  }
  const openModal = (propertyId) => {
    // console.log("Opening modal for propertyId:", propertyId);
    setPropertyIdToDelete(propertyId);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    // console.log("Closing modal");
    setModalOpen(false);
  };
  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  const handleDeleteProperty = (propertyId) => {
    // Construct the URL with the adminId and propertyId
    const url = `${process.env.REACT_APP_API_IP}/property/delete-property/${adminId}/dcjiebdu/dejbdyw`;

    // Prepare the request body
    const requestBody = {
      id: propertyId,
      deleteReason: deleteReason,
    };

    // Send the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        // console.log("Response status:", response.status);
        // Handle the response here
        if (response.ok) {
          // console.log("Property deleted successfully");
          // You can add code here to handle success, e.g., removing the property from the list
          // Reload the page
          window.location.reload();
        } else {
          console.error("Error deleting property");
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });

    // Close the modal after submitting
    closeModal();
  };
  const navigate = useNavigate();

  const handleEditPropertyButton = (id) => {
    navigate(`/edit-property?id=${id}`);
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle
        tag="h4"
        className="text-center border-bottom p-3 mb-0 d-flex justify-content-between"
      >
        <div className="property-name">
          <i className="bi bi-houses me-2"></i>
          Delted Properties
        </div>
        <div className="property-count">Total Properties: {propertyCount}</div>
      </CardTitle>
      <CardBody>
      {loading ? (
        <div className="loader-container">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
        <Modal isOpen={showModal} backdrop="static" keyboard={false}>
          <ModalHeader>
            <span>Password Verification </span>
            <Link to="/starter" style={{ "padding-left": "175px" }}>
              <Button>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </ModalHeader>

          <ModalBody>
            <Form onSubmit={handleModalSubmit}>
              <FormGroup>
                <Label for="superpassword">Password:</Label>
                <Input
                  type="password"
                  id="superpassword"
                  required
                  value={superpassword}
                  onChange={(e) => setSuperpassword(e.target.value)}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
              <Button type="submit">Continue</Button>
            </Form>{" "}
          </ModalBody>
        </Modal>
        <div className="filter-buttons">
          {/* <button onClick={() => handleFilter("unverified")} style={{
            backgroundColor: unverified ? "green" : "initial",
            color: unverified ? "white" : "initial",
            marginRight: '8px',
            height: '40px',
            borderRadius: '10px'
          }}>
            Unverified Properties
          </button> */}
          <button
            className="sortButton rounded"
            onClick={handleShow}
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            <FontAwesomeIcon icon={faFilter} />
          </button>
       
          <button
        onClick={handleSearchClick}
        style={{
          height: "40px",
          float: "right",
          borderRadius: "10px",
          padding: "0 20px",
          backgroundColor: "#007bff",
          color: "#fff",
          border: "none",
          cursor: "pointer",
        }}
      >
        Search
      </button>
          <input
        type="text"
        placeholder="Search..."
        value={inputValue}
        onChange={handleInputChange}
        style={{
          float: "right",
              height: "40px",
              width: "190px",
              borderRadius: "10px",
              padding: "10px",
              marginRight : "10px"
        }}
      />
      
        </div>
        <div className="">
          <div className="header">{/* ... */}</div>,
          <div className="row justify-content-center">
            {/* Main Contain */}
            <div className="mainContain py-2 col-md-12">
              <div className="mb-3 " style={{ maxWidth: "100%" }}>
                {filteredProperties.map((property, index) => (
                  <div
                    className="row shadow-sm no-gutters rounded-2"
                    key={index}
                    id="propertyCard"
                  >
                    <div className="col-md-4 p-0 reportimage-box">
                      <div id="GenderBox">
                        <span className="">{property.memberedAllowed}</span>
                      </div>
                      <CustomCarousel mediaUrls={property.photoUrls} />
                    </div>
                    <div className="col-md-6" id="propertyCard-body">
                      <Link
                        className="text-decoration-none text-dark"
                        to={`/particular-property/${property.id}`}
                      >
                        <div id="card-Heading">{property.title}</div>
                        <div id="card-location" className="row">
                          <div id="" className="col">
                            <FontAwesomeIcon
                              className="me-2 grey"
                              icon={faLocationDot}
                            />{" "}
                            {property.address && property.address.area}{" "}
                            {property.address && property.address.city}{" "}

                            {property.address && property.address.pincode}
                          </div>
                          <div className="LaptopHide col">
                            Type : {property.furnishedType}
                          </div>
                        </div>
                        <div id="card-Details" className="row">
                          <div id="Details" className="col">
                            <span className="grey">Available For :</span>{" "}
                            {property.memberedAllowed}
                          </div>
                          <div id="Details" className="col">
                            <span className="grey">Property Type :</span>{" "}
                            {property.subtype.length > 12
                              ? `${property.subtype.substring(0, 12)}..`
                              : property.subtype}{" "}
                          </div>
                        </div>
                        <div id="card-Details" className="row">
                          <div id="Details" className="col">
                            <span className="grey">Property Size :</span>{" "}
                            {property.bedroom} BHK
                          </div>
                          <div id="Details" className="col">
                            <span className="grey">Status :</span> Available
                          </div>
                        </div>
                        <div id="emnities" className=" mobileAndTab-hide">
                          {property.amenities.includes("electricity") && (
                            <img
                              className="amenities-size"
                              src="/emenities/Electricity.png"
                              alt="Amenities"
                            />
                          )}
                           {property.amenities.includes("balcony") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/balcony.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("cctv") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/CCTV.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("cooking") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Cooking.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("24*7-water") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Water.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("house-keeping") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Housekeeping.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("2-wheeler-parking") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Parking.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("fans") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Fen.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("fridge") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Fridge.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("laundry") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Laundry.png"
                              alt="Amenities"
                            />
                          )}
                          {property.amenities.includes("security") && (
                            <img
                              className=" amenities-size"
                              src="/emenities/Security.png"
                              alt="Amenities"
                            />
                          )}
                        </div>
                        <div id="rentBox" className="row">
                          <div className="col p-0 mobileAndTab-hide">
                            <b
                              style={{
                                fontSize: "23px",
                                color: "rgba(0, 0, 0, 0.6)",
                              }}
                            >
                              {/* {property.rent
                                ? `₹ ${property.rent}`
                                : `₹ ${property.rentEachHead}`} */}
                              {/* {property.type === "private flat"
  ? `₹ ${property.totalFlatRent}`
  : property.sharingType?.singleRent
  ? `₹ ${property.sharingType.singleRent}`
  : property.sharingType?.doubleRent
  ? `₹ ${property.sharingType.doubleRent}`
  : property.sharingType?.tripleRent
  ? `₹ ${property.sharingType.tripleRent}`
  : property.rentEachHead
  ? `₹ ${property.rentEachHead}`
  : "null"} */}
                              {/* ₹ {property.rent || property.totalRent || property.totalFlatRent} */}
                              {property.type === "private flat"
                                ? `₹ ${property.totalFlatRent}`
                                : property.sharingType?.singleRent
                                ? `₹ ${property.sharingType.singleRent}`
                                : property.sharingType?.doubleRent
                                ? `₹ ${property.sharingType.doubleRent}`
                                : property.sharingType?.tripleRent
                                ? `₹ ${property.sharingType.tripleRent}`
                                : property.rentEachHead
                                ? `₹ ${property.rentEachHead}`
                                : "null"}
                            </b>{" "}
                            <span className="grey">/per month</span>
                          </div>
                          <div
                            className="col p-0 mobileAndTab-hide "
                            style={{ color: "rgba(0, 0, 0, 0.7)" }}
                          >
                            {" "}
                            <span className="grey">Deposit </span>:{" "}
                            {property.deposit} Rent{" "}
                          </div>
                        </div>
                      </Link>
                      <div className="rounded-4 status-box" id="rentBox">
                        <div className="col p-0 mobileAndTab-hide LaptopHide">
                          <b
                            style={{
                              marginLeft: "2px",
                              fontSize: "20px",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          >
                            {/* ₹ {property.rent || property.totalRent} */}
                            {property.type === "private flat"
                              ? `₹ ${property.totalFlatRent}`
                              : property.sharingType?.singleRent
                              ? `₹ ${property.sharingType.singleRent}`
                              : property.sharingType?.doubleRent
                              ? `₹ ${property.sharingType.doubleRent}`
                              : property.sharingType?.tripleRent
                              ? `₹ ${property.sharingType.tripleRent}`
                              : property.rentEachHead
                              ? `₹ ${property.rentEachHead}`
                              : "null"}
                          </b>{" "}
                          <span className="grey" style={{ fontSize: "12px" }}>
                            /per month
                          </span>
                        </div>
                        <div className="col p-0 mobileAndTab-hide  ">
                          Property Status :{" "}
                          {property.isVerified ? (
                            !property.isRentedout ? (
                              <span className="text-success fw-bold">Live</span>
                            ) : (
                              <span className="text-danger fw-bold">
                                Rent Out
                              </span>
                            )
                          ) : (
                            <span className="text-warning fw-bold">
                              Under Review
                            </span>
                          )}
                        </div>
                        <div className="toggle-container ">
                          <p className="toggle-text ms-0">Available</p>
                          <label className="toggle-switch">
                            <input
                              type="checkbox"
                              checked={property.isRentedout}
                              onChange={() => {
                                handleToggle(property.id);
                              }}
                            />
                            <span className="slider"></span>
                          </label>
                          <p className="toggle-text">Rent Out</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2 rounded-4 " id="card-ButtonBox">
                      <div className="ownerBox">
                        <span>Owner :</span>
                        <br /> {property.name}
                        {property.isDeleted !== 0 && (
                        <div className="ownerBox">
                          <span>Delete Reason :</span>
                          <br /> {property.deleteReason}
                        </div>
                      )}
                      </div>
                     
                      <div className="LaptopHide">
                        <b
                          style={{
                            marginLeft: "2px",
                            fontSize: "20px",
                            color: "rgba(0, 0, 0, 0.6)",
                          }}
                        >
                          {/* ₹ {property.rent} */}
                          {property.type === "private flat"
                            ? `₹ ${property.totalFlatRent}`
                            : property.sharingType?.singleRent
                            ? `₹ ${property.sharingType.singleRent}`
                            : property.sharingType?.doubleRent
                            ? `₹ ${property.sharingType.doubleRent}`
                            : property.sharingType?.tripleRent
                            ? `₹ ${property.sharingType.tripleRent}`
                            : property.rentEachHead
                            ? `₹ ${property.rentEachHead}`
                            : "null"}
                        </b>{" "}
                        <span className="grey" style={{ fontSize: "12px" }}>
                          /per month
                        </span>
                      </div>
                      {property.isDeleted === 0 && (
                        <Button
                          id="contact-owner"
                          onClick={() => openModal(property.id)}
                        >
                          Delete Property
                        </Button>
                      )}
                      <Button
                        // className=" mobileAndTab-hide"
                        id="contact-owner"
                        
                        onClick={() => handleEditPropertyButton(property.id)}
                      >
                        Edit Property
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* Modal for delete reason input */}
          <Modal isOpen={modalOpen} toggle={closeModal}>
            <ModalHeader toggle={closeModal}>Enter Delete Reason</ModalHeader>
            <ModalBody>
              <Input
                type="text"
                placeholder="Delete Reason"
                value={deleteReason}
                onChange={(e) => setDeleteReason(e.target.value)}
              />
              <Button
                color="danger"
                onClick={() => handleDeleteProperty(propertyIdToDelete)}
                className="mt-3"
              >
                Confirm Delete
              </Button>
            </ModalBody>
          </Modal>
          {propertyCount > 10 && (
            <div className="paginationBox">
              <Button
                className="paginationBtn"
                onClick={goToPreviousPage}
                disabled={currentPage === 0}
              >
                {" "}
                <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
              </Button>
              <span>{currentPage + 1}</span>
              <Button className="paginationBtn" onClick={goToNextPage}>
                Next <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            </div>
          )}
        </div>
        </>
      )};
         <div
            class="modal fade"
            id="exampleModal"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Filter
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <Form>
                    <FormGroup className="mt-3">
                      <Label>Type :</Label>
                      <div className="location-groups">
                        {filtertype &&
                          filtertype.map((button) => (
                            <div
                              key={button.value}
                              className={`location-button rounded-pill ${
                                type === button.value ? "selected" : ""
                              }`}
                              value={type}
                              onClick={() => settype(button.value)}
                            >
                              {button.label}
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="location">City :</Label>
                      <Input
                        id="location"
                        placeholder="search..."
                        value={locationFilter}
                        onChange={(e) => {
                          handleLocationChange(e.target.value);
                        }}
                      />
                      <div className="location-groups mt-2">
                        {cityFilter &&
                          cityFilter.map((button) => (
                            <div
                              key={button.value}
                              className={`location-button rounded-pill ${
                                city === button.cityName ? "selected" : ""
                              }`}
                              value={city}
                              onClick={() => {
                                handleLocationChange(button.cityName);
                              }}
                            >
                              {button.cityName}
                            </div>
                          ))}
                      </div>
                    </FormGroup>
                    <FormGroup>
                      <Label for="location">Area :</Label>

                      <NearbyLocations
                        handleareaChange={handleareaChange}
                        area={area}
                      />
                      <div className="location-groups">
                        {area &&
                          area.map((item, index) => {
                            // console.log(item);
                            return (
                              item != "" && (
                                <div
                                  key={index}
                                  className={`location-button rounded-pill selected`}
                                  onClick={() => {
                                    removearea(item);
                                  }}
                                >
                                  {item}
                                </div>
                              )
                            );
                          })}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Occupancy Type :</Label>
                      <div className="location-groups">
                        {Occupancy.map((button, index) => (
                          <div
                            key={index}
                            className={`location-button rounded-pill ${
                              occupancyFilter[index].isSelected
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => handleOccupancyChange(index)}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <Label>Sub Type :</Label>
                      <div className="location-groups">
                        {filtersubtype &&
                          filtersubtype.map((button) => (
                            <div
                              key={button.value}
                              className={`location-button rounded-pill ${
                                subtype.includes(button.value) ? "selected" : ""
                              }`}
                              onClick={() => {
                                handlesubtypeChange(button.value);
                              }}
                            >
                              {button.label}
                            </div>
                          ))}
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label>Preferences :</Label>
                      <div className="location-groups">
                        {Preferences.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              preferencesFilter.includes(button.value)
                                ? "selected"
                                : ""
                            }`}
                            onClick={() => {
                              handlePreferencesChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
  <Label>BedRoom :</Label>
  <div className="location-groups">
    {Bedroom.map((button) => (
      <div
        key={button.value}
        className={`location-button rounded-pill ${
          BedroomFilter.includes(button.value) ? "selected" : ""
        }`}
        onClick={() => {
          handleBedroomChange(button.value);
        }}
      >
        {button.label}
      </div>
    ))}
  </div>
</FormGroup>


                    <FormGroup className="mt-3">
                      <Label for="budget">Budget :</Label>
                      <div className="range-bar">
                        <MultiRangeSlider
                          barInnerColor="#0C6EFA"
                          ruler="false"
                          min={2000}
                          max={50000}
                          step={1000}
                          minValue={minValue}
                          maxValue={maxValue}
                          onInput={(e) => {
                            handleInput(e);
                          }}
                        />
                      </div>
                    </FormGroup>

                    <FormGroup className="mt-3">
                      <Label>Gender :</Label>
                      <div className="location-groups">
                        {gender.map((button) => (
                          <div
                            key={button.value}
                            className={`location-button rounded-pill ${
                              genderFilter === button.value ? "selected" : ""
                            }`}
                            value={genderFilter}
                            onClick={() => {
                              handleGenderChange(button.value);
                            }}
                          >
                            {button.label}
                          </div>
                        ))}
                      </div>
                    </FormGroup>
                    {/* <FormGroup className="mt-3">
              <Label>Ocupancy Type :</Label>
              <div className="location-groups">
                {Ocupancy.map((button) => (
                  <div
                    key={button.value}
                    className={`location-button rounded-pill ${
                      occupancyFilter === button.value ? "selected" : ""
                    }`}
                    value={locationFilter}
                    onClick={() => {
                      handleOccupancyChange(button.value);
                    }}
                  >
                    {button.label}
                  </div>
                ))}
              </div>
            </FormGroup> */}
                    <FormGroup className="mt-3">
  <Label>Furnished Type :</Label>
  <div className="location-groups">
    {Furnished.map((button) => (
      <div
        key={button.value}
        className={`location-button rounded-pill ${
          furnishedFilter.includes(button.value) ? "selected" : ""
        }`}
        onClick={() => {
          handleFurnishedChange(button.value);
        }}
      >
        {button.label}
      </div>
    ))}
  </div>
</FormGroup>

                    <FormGroup className="mt-3">
                      <Label>Availability :</Label>
                      <div className="location-groups">
                        <input
                          type="date"
                          className="form-control"
                          value={shiftingDate}
                          onChange={(e) => setShiftingDate(e.target.value)}
                        />
                      </div>
                    </FormGroup>
                    <FormGroup className="mt-3">
                      <div className="location-groups">
                        <input
                          type="Button"
                          className="form-control btn btn-primary"
                          value={"Reset Filter"}
                          onClick={resetfilter}
                        />
                      </div>
                    </FormGroup>
                  </Form>
                </div>
              </div>
            </div>
          </div>
      </CardBody>
    </Card>
  );
};

export default AllDeletedProperties;
