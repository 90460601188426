import "./UnverifiedProperties.css";
import { React, useEffect, useState } from "react";
import CustomCarousel from "../../components/CustomCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import {
  faLocationDot,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import axios from "../../api/axios";
import ReportedProperty from "./ParticularReportedProperty";
let propData = {};

const AllReportedProperties = () => {
  const [superpassword, setSuperpassword] = useState("1");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const adminId = Cookies.get("id");
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [currentPage, setCurrentPage] = useState(1); // Initialize currentPage to 1
  const [propertyData, setPropertyData] = useState([]); // State to hold property data
  const [totalPages, setTotalPages] = useState(1);
  const [filterType, setFilterType] = useState("all");
  const [propertyCount, setPropertyCount] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [propertyIdToDelete, setPropertyIdToDelete] = useState(null);
  // const [propertyData, setPropertyData] = useState([]);
  const [allClicked, setAllClicked] = useState(false);
  const [pendingClicked, setPendingClicked] = useState(true);
  const [reviewedClicked, setReviewedClicked] = useState(false);

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
  ];
  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };
  useEffect(() => {
    // Update visibleData when currentPage changes
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    const currentData = propertyData.slice(startIndex, endIndex);
    setFilteredProperties(currentData);
  }, [currentPage, propertyData]);

  // Update total pages when propertyData changes
  useEffect(() => {
    if (propertyData.length > 0) {
      const pages = Math.ceil(propertyData.length / 10);
      setTotalPages(pages);
    }
  }, [propertyData]);

  // Function to handle initial fetching of properties
  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_IP}/report/Reported-properties/dubyed/fnejbehy`
        );
        propData = res.data;
        setPropertyData(propData);
        setPropertyCount(propData.length); // Set property count
        handleFilter("Pending");
      } catch (error) {
        console.error("Error while fetching reported properties :", error);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    // console.log('use effect 1 called!!!')
    const fetchProperties = async () => {
      // let apiUrl = "http://15.206.66.176:8080/report/Reported-properties"

      try {
        const res = await axios.post(
          `${process.env.REACT_APP_API_IP}/report/Reported-properties/dubyed/fnejbehy`
        );
        propData = res.data;
        setPropertyData(propData);
        setPropertyCount(propData.length); // Set property count
        handleFilter("Pending");
        // console.log(propData);
      } catch (error) {
        // console.log("Error while fetching reported properties :" + error);
      }
    };

    fetchProperties();
  }, []); // Ensure the useEffect runs only once by passing an empty dependency
  // console.log(propertyData);
  const handleToggle = async (id) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_IP}/property/toggleStatus/${adminId}/${id}`
    );
    if (response.ok) {
      window.location.reload();
    } else {
      // console.log(response);
    }
  };

  useEffect(() => {
    // console.log('use effect 2 called!!!')
  }, [propertyData]);

  const handleFilter = (filter) => {
    if (filter === "Reviewed") {
      setAllClicked(false);
      setPendingClicked(false);
      setReviewedClicked(true);
    }
    if (filter === "Pending") {
      setAllClicked(false);
      setPendingClicked(true);
      setReviewedClicked(false);
    }
    if (filter === "all") {
      setAllClicked(true);
      setPendingClicked(false);
      setReviewedClicked(false);
    }
    if (filter === "Reviewed" || filter === "Pending") {
      let arr = [];
      for (let i = 0; i < propData.length; i++) {
        // console.log(propData[i].status===filter);
        if (propData[i].status === filter) arr.push(propData[i]);
      }
      // console.log(arr);
      setPropertyData(arr);
      // console.log(propertyData)
    } else {
      setPropertyData(propData);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // let totalPages = 1;
  let visibleData = filteredProperties;
  if (filteredProperties.length && filteredProperties.length > 10) {
    totalPages = Math.ceil(filteredProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = filteredProperties.slice(startIndex, endIndex);
  }
  const openModal = (propertyId) => {
    // console.log("Opening modal for propertyId:", propertyId);
    setPropertyIdToDelete(propertyId);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    // console.log("Closing modal");
    setModalOpen(false);
  };

  const handleDeleteProperty = (propertyId) => {
    // Construct the URL with the adminId and propertyId
    const url = `${process.env.REACT_APP_API_IP}/property/delete-property/${adminId}/dcjiebdu/dejbdyw`;

    // Prepare the request body
    const requestBody = {
      id: propertyId,
      deleteReason: deleteReason,
    };

    // Send the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        // console.log("Response status:", response.status);
        // Handle the response here
        if (response.ok) {
          // console.log("Property deleted successfully");
          // You can add code here to handle success, e.g., removing the property from the list
          // Reload the page
          window.location.reload();
        } else {
          console.error("Error deleting property");
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });

    // Close the modal after submitting
    closeModal();
  };
  const navigate = useNavigate();

  const handleEditPropertyButton = (id) => {
    navigate(`/edit-property?id=${id}`);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle
        tag="h4"
        className="text-center border-bottom p-3 mb-0 d-flex justify-content-between"
      >
        <div className="property-name">
          <i className="bi bi-house-slash me-2"></i>
          Reported Properties
        </div>
        <div className="property-count">Total Properties: {propertyCount}</div>
      </CardTitle>
      <CardBody>
        <Modal isOpen={showModal} backdrop="static" keyboard={false}>
          <ModalHeader>
            <span>Password Verification </span>
            <Link to="/starter" style={{ "padding-left": "175px" }}>
              <Button>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </ModalHeader>

          <ModalBody>
            <Form onSubmit={handleModalSubmit}>
              <FormGroup>
                <Label for="superpassword">Password:</Label>
                <Input
                  type="password"
                  id="superpassword"
                  required
                  value={superpassword}
                  onChange={(e) => setSuperpassword(e.target.value)}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
              <Button type="submit">Continue</Button>
            </Form>{" "}
          </ModalBody>
        </Modal>
        <div className="filter-buttons">
          <button
            onClick={() => handleFilter("all")}
            style={{
              backgroundColor: allClicked ? "rgb(40, 125, 253)" : "initial",
              color: allClicked ? "white" : "initial",
              marginRight: "8px",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            All
          </button>
          <button
            onClick={() => handleFilter("Reviewed")}
            style={{
              backgroundColor: reviewedClicked
                ? "rgb(40, 125, 253)"
                : "initial",
              color: reviewedClicked ? "white" : "initial",
              marginRight: "8px",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            Checked Listings
          </button>
          <button
            onClick={() => handleFilter("Pending")}
            style={{
              backgroundColor: pendingClicked ? "rgb(40, 125, 253)" : "initial",
              color: pendingClicked ? "white" : "initial",
              height: "40px",
              borderRadius: "10px",
            }}
          >
            Pending
          </button>
        </div>

        {propData.length > 0 ? (
          <div className="">
            <div className="header">{/* ... */}</div>
            <div className="row justify-content-center">
              {/* Main Contain */}
              <div className="mainContain py-2 col-md-12">
                <div className="mb-3 " style={{ maxWidth: "100%" }}>
                  {propertyData.map((prop, index) => {
                    const property = prop.property;
                    const ownerNumber = prop?.owner?.number;
                    const userNumber = prop?.user?.number;
                    const rentReason = prop?.reportTopic;
                    return (
                      <div
                        className="row shadow-sm no-gutters rounded-2 mobile-report-card"
                        key={index}
                        id="propertyCard"
                      >
                        <div className="col-md-4 p-0 report-property reportimage-box">
                          <div id="GenderBox">
                            <span className="">{property.memberedAllowed}</span>
                          </div>
                          <CustomCarousel mediaUrls={property.photoUrls} />
                        </div>
                        <div
                          className="col-md-6 report-property-body"
                          id="propertyCard-body"
                        >
                          <Link
                            className="text-decoration-none text-dark"
                            to={`/reported/${prop.id}`}
                          >
                            {/* <div id="card-Heading">{property.title}</div> */}
                            <div
                              style={{
                                fontSize: "28px",
                              }}
                            >
                              {property.title}
                            </div>
                            <div id="card-location" className="row">
                              <div id="" className="col">
                                <FontAwesomeIcon
                                  className="me-2 grey"
                                  icon={faLocationDot}
                                />{" "}
                                {property.address && property.address.area}{" "}
                                {property.address && property.address.city}{" "}

                                {property.address && property.address.pincode}
                              </div>
                              <div className="LaptopHide col">
                                Type : {property.furnishedType}
                              </div>
                            </div>
                            <div id="card-Details" className="row">
                              <div id="Details" className="col">
                                <span className="grey">Available For :</span>{" "}
                                {property.memberedAllowed}
                              </div>
                              <div id="Details" className="col">
                                <span className="grey">Property Type :</span>{" "}
                                {property.subtype.length > 12
                                  ? `${property.subtype.substring(0, 12)}..`
                                  : property.subtype}{" "}
                              </div>
                            </div>
                            <div id="card-Details" className="row">
                              <div id="Details" className="col">
                                <span className="grey">Property Size :</span>{" "}
                                {property.bedroom} BHK
                              </div>
                              <div id="Details" className="col">
                                <span className="grey">Status :</span> Available
                              </div>
                            </div>
                            <div id="emnities" className=" mobileAndTab-hide">
                              {property.amenities.includes("electricity") && (
                                <img
                                  className="amenities-size"
                                  src="/emenities/Electricity.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("balcony") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/balcony.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("balcony") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/balcony.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("cctv") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/CCTV.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("cooking") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Cooking.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("24*7-water") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Water.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("house-keeping") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Housekeeping.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes(
                                "2-wheeler-parking"
                              ) && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Parking.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("fans") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Fen.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("fridge") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Fridge.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("laundry") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Laundry.png"
                                  alt="Amenities"
                                />
                              )}
                              {property.amenities.includes("security") && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Security.png"
                                  alt="Amenities"
                                />
                              )}
                            </div>
                            <div id="rentBox" className="row">
                              <div className="col p-0 mobileAndTab-hide">
                                <b
                                  style={{
                                    fontSize: "23px",
                                    color: "rgba(0, 0, 0, 0.6)",
                                  }}
                                >
                                  {property.type === "private flat"
                                    ? `₹ ${property.totalFlatRent}`
                                    : property.sharingType.singleRent
                                    ? `₹ ${property.sharingType.singleRent}`
                                    : property.sharingType.doubleRent
                                    ? `₹ ${property.sharingType.doubleRent}`
                                    : property.sharingType.tripleRent
                                    ? `₹ ${property.sharingType.tripleRent}`
                                    : `₹ ${property.rentEachHead}`}
                                </b>{" "}
                                <span className="grey">/per month</span>
                              </div>
                              <div
                                className="col p-0 mobileAndTab-hide "
                                style={{ color: "rgba(0, 0, 0, 0.7)" }}
                              >
                                {" "}
                                <span className="grey">Deposit </span>:{" "}
                                {property.deposit} Rent{" "}
                              </div>
                            </div>
                          </Link>
                          <div className="rounded-4 status-box" id="rentBox">
                            <div className="LaptopHide">
                              <b
                                style={{
                                  marginLeft: "2px",
                                  fontSize: "20px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                {/* ₹ {property.rent || property.totalRent} */}
                                {property.type === "private flat"
                                  ? `₹ ${property.totalFlatRent}`
                                  : property.sharingType?.singleRent
                                  ? `₹ ${property.sharingType.singleRent}`
                                  : property.sharingType?.doubleRent
                                  ? `₹ ${property.sharingType.doubleRent}`
                                  : property.sharingType?.tripleRent
                                  ? `₹ ${property.sharingType.tripleRent}`
                                  : property.rentEachHead
                                  ? `₹ ${property.rentEachHead}`
                                  : "null"}
                              </b>{" "}
                              <span
                                className="grey"
                                style={{ fontSize: "12px" }}
                              >
                                /per month
                              </span>
                            </div>
                            <div className=" col p-0 mobileAndTab-hide ">
                              Property Status :{" "}
                              {property.isVerified ? (
                                !property.isRentedout ? (
                                  <span className="text-success fw-bold">
                                    Live
                                  </span>
                                ) : (
                                  <span className="text-danger fw-bold">
                                    Rent Out
                                  </span>
                                )
                              ) : (
                                <span className="text-warning fw-bold">
                                  Under Review
                                </span>
                              )}
                            </div>
                            <div className="toggle-container ">
                              <p className="toggle-text ms-0">Available</p>
                              <label className="toggle-switch">
                                <input
                                  type="checkbox"
                                  checked={property.isRentedout}
                                  onChange={() => {
                                    handleToggle(property.id);
                                  }}
                                />
                                <span className="slider"></span>
                              </label>
                              <p className="toggle-text">Rent Out</p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-2 rounded-4 report-property-contactbox mobile-report"
                          id="card-ButtonBox"
                        >
                          <div
                            className="ownerBox"
                            style={{
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              maxWidth: "400px",
                              margin: "10px auto",
                              backgroundColor: "#f9f9f9",
                              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <div style={{ marginBottom: "5px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Owner:{" "}
                              </span>
                              <span>{property.name}</span>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Owner Number:{" "}
                              </span>
                              <span>{ownerNumber}</span>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                User Number:{" "}
                              </span>
                              <span>{userNumber}</span>
                            </div>
                            <div style={{ marginBottom: "5px" }}>
                              <span style={{ fontWeight: "bold" }}>
                                Rent Reason:{" "}
                              </span>
                              <span>{rentReason}</span>
                            </div>
                            <div>
                              <span style={{ fontWeight: "bold" }}>
                                Status:{" "}
                              </span>
                              {prop.status === "Reviewed" ? (
                                <span style={{ color: "green" }}>Reviewed</span>
                              ) : (
                                <span style={{ color: "red" }}>Pending</span>
                              )}
                            </div>
                          </div>

                          <div className="LaptopHide">
                            <b
                              style={{
                                marginLeft: "2px",
                                fontSize: "20px",
                                color: "rgba(0, 0, 0, 0.6)",
                              }}
                            >
                              {/* ₹ {property.rent} */}
                              {property.type === "private flat"
                                ? `₹ ${property.totalFlatRent}`
                                : property.sharingType?.singleRent
                                ? `₹ ${property.sharingType.singleRent}`
                                : property.sharingType?.doubleRent
                                ? `₹ ${property.sharingType.doubleRent}`
                                : property.sharingType?.tripleRent
                                ? `₹ ${property.sharingType.tripleRent}`
                                : property.rentEachHead
                                ? `₹ ${property.rentEachHead}`
                                : "null"}
                            </b>{" "}
                            <span className="grey" style={{ fontSize: "12px" }}>
                              /per month
                            </span>
                          </div>
                          {property.isDeleted === 0 && (
                            <Button
                              id="contact-owner"
                              onClick={() => openModal(property.id)}
                            >
                              Delete Property
                            </Button>
                          )}
                          <Button
                            // className=" mobileAndTab-hide"
                            id="contact-owner"
                            
                            onClick={() =>
                              handleEditPropertyButton(property.id)
                            }
                          >
                            Edit Property
                          </Button>
                        </div>
                      </div>
                    );
                  })}
                  {filteredProperties.length > 10 && (
                    <div className="paginationBox">
                      <Button
                        className="paginationBtn"
                        onClick={goToPreviousPage}
                        disabled={currentPage === 1} // Disable Previous button on first page
                      >
                        <FontAwesomeIcon icon={faArrowLeft} /> Previous
                      </Button>
                      <span className="currentPageNumber">
                        Page {currentPage} of {totalPages}
                      </span>
                      <Button
                        className="paginationBtn"
                        onClick={goToNextPage}
                        disabled={currentPage === totalPages} // Disable Next button on last page
                      >
                        Next <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Modal for delete reason input */}
            <Modal isOpen={modalOpen} toggle={closeModal}>
              <ModalHeader toggle={closeModal}>Enter Delete Reason</ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  placeholder="Delete Reason"
                  value={deleteReason}
                  onChange={(e) => setDeleteReason(e.target.value)}
                />
                <Button
                  color="danger"
                  onClick={() => handleDeleteProperty(propertyIdToDelete)}
                >
                  Confirm Delete
                </Button>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <center style={{ marginTop: "30px" }}>
            <h2>No Reporeted Properties To Show</h2>
          </center>
        )}
      </CardBody>
    </Card>
  );
};

export default AllReportedProperties;
