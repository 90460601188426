import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faArrowLeft,
  faCircleXmark,
  faClose,
  faPhone,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { Button, FormGroup, Input, Label } from "reactstrap";
import axios from "axios";
import imageCompression from 'browser-image-compression';
import getMobileOS from "../../../helpers/os";
import "./step.css";
import Cookies from "js-cookie";



const Step4 = ({
  propertyDetails,
  updatePropertyDetails,
  currentStep,
  setCurrentStep,
}) => {
  const [address, setAddress] = useState({
    houseno: "",
    area: "",
    pincode: "",
    city: "",
    streetAddress: "",
  });
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [thankyoumodal, setThankyoumodal] = useState(false);
  const os = getMobileOS();
  const Navigate = useNavigate();
  const thankyouModal = () => {
    if (success === "Error") {
      setThankyoumodal(false);
    } else {
      if (os === "Android") {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.codingislife.citynect";
      } else if (os === "iOS") {
        window.location.href =
          "https://apps.apple.com/app/your_app_name/idyour_app_id";
      } else {
        Navigate("/myaccount/listedproperty");
      }
    }
  };

  const onDrop = async (acceptedFiles) => {
    const compressedPhotos = await Promise.all(
      acceptedFiles.map(async (file) => compressImage(file))
    );
    setPhotos((prevPhotos) => [...prevPhotos, ...compressedPhotos]);
  };

  const compressImage = async (file) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      const compressedFile = await imageCompression(file, options);
      return compressedFile;
    } catch (error) {
      console.error("Image compression error:", error);
      return null;
    }
  };

  const handleBlur = (e) => {
    handleAddressChange(e.target.name, e.target.value);
  };

  const handleAddressChange = (field, value) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));

    const updatedAddress = { ...propertyDetails, address: { ...address, [field]: value } };
    updatePropertyDetails(updatedAddress);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: "image/*",
    maxFiles: 10,
  });

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...photos];
    updatedPhotos.splice(index, 1);
    setPhotos(updatedPhotos);
  };


   const handleSubmit = async () => {
    const isValid = validateForm();
    // console.log(isValid);
    if (isValid) {
      setLoading(true);
      setThankyoumodal(true);
      try {
        // await makeAddress()
        const formData = new FormData();
        // console.log(propertyDetails)
        formData.append(
          "property",
          JSON.stringify({
            ownerId: propertyDetails.ownerId,
            name: propertyDetails.name,
            phone: propertyDetails.phone,
            type: propertyDetails.type,
            subtype: propertyDetails.subtype,
            bedroom: propertyDetails.bedroom,
            furnishedType: propertyDetails.furnishedType,
            amenities: propertyDetails.amenities,
            memberedAllowed: propertyDetails.memberedAllowed,
            nonVegAllowed: propertyDetails.nonVegAllowed,
            instructions: propertyDetails.instructions,
            availableFrom: propertyDetails.availableFrom,
            washroomAttached: propertyDetails.washroomAttached,
            preferenceTypes : propertyDetails.preferenceTypes,
            sharingType: {
              singleSharing:"single",
              singleRent: propertyDetails.sharingType.singleRent,
              doubleSharing: propertyDetails.sharingType.doubleSharing,
              doubleRent: propertyDetails.sharingType.doubleRent,
              tripleSharing: propertyDetails.sharingType.tripleSharing,
              tripleRent: propertyDetails.sharingType.tripleRent==null? "": propertyDetails.sharingType.tripleRent,
            },
            totalFlatRent: propertyDetails.totalFlatRent,
            isNegotiable: propertyDetails.isNegotiable,
            deposit: propertyDetails.deposit,
            noticePeriond: propertyDetails.noticePeriond,
            address: propertyDetails.address,
            description: propertyDetails.description,
            callAllowed: propertyDetails.callAllowed,
          })
        );
        photos.forEach((image) => {
          formData.append("photos", image);
        });
        // console.log(formData.get("property"));
        // return
        const response = await axios.post(
          `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/djjfbeu/add-with-user-check/${Cookies.get("id")}`,
          formData
        );
        if (response.status === 200) {
          setLoading(false);
          setSuccess(true);
        } else {
          setSuccess("Error");
          setLoading(false);
          console.error("Failed to submit property:", response.status);
        }
      } catch (error) {
        setLoading(false);
        setSuccess("Error");
        console.error("Error submitting property:", error);
      }
    }
  };

  const validateForm = () => {
    const newErrors = [];
  
    if (!address.streetAddress && address.streetAddress !== "") { // Check if not empty
      newErrors.push({ name: "streetAddress", error: "Please enter the street address." });
    }
    if (!address.houseno && address.houseno !== "") { // Check if not empty
      newErrors.push({ name: "houseno", error: "Please enter the society name." });
    }
    if (!address.pincode && address.pincode !== "") { // Check if not empty
      newErrors.push({ name: "pincode", error: "Please enter the pincode." });
    }
    if (!address.city && address.city !== "") { // Check if not empty
      newErrors.push({ name: "city", error: "Please enter the city." });
    }
    if (!address.area && address.area !== "") { // Check if not empty
      newErrors.push({ name: "area", error: "Please enter the area." });
    }
  
    if (photos.length < 1) {
      newErrors.push({ name: "photos", error: "Please upload at least one photos." });
    } else if (photos.length > 10) {
      newErrors.push({ name: "photos", error: "Please upload a maximum of ten photos." });
    }
  
    setErrors(newErrors);
    return newErrors.length === 0;
  };
  

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
// console.log(propertyDetails);
  const handleSelect = (field, fullAddress, place) => {
    if (fullAddress) {
      const splitAddress = fullAddress.split(',')[0]; // Take only the first part before comma
      const pinCodeMatch = splitAddress.match(/\b\d{6}\b/);
      const zipCode = pinCodeMatch ? pinCodeMatch[0] : null;
      let city = place?.address_components?.find(component => component.types.includes("locality"))?.long_name || "";
  
      if (city.includes(',')) {
        city = city.split(',')[0]; // Take only the first part before comma for city
      }
      
      // Trim the city to only the first word
      city = city.split(' ')[0];
  
      setAddress((prevAddress) => ({
        ...prevAddress,
        [field]: splitAddress,
        ...(field === "streetAddress" && { pincode: zipCode, city: city }),
      }));
    }
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
    if (propertyDetails.photoUrls) {
      setPhotos(propertyDetails.photoUrls.map((url) => ({ url })));
    }
  }, [propertyDetails.photoUrls]);

  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initAutocomplete`;
    script.async = true;
    script.defer = true;
    document.head.appendChild(script);

    window.initAutocomplete = () => {
      const inputs = ["houseno", "streetAddress", "area"];
      inputs.forEach((input) => {
        const inputElement = document.getElementById(input);
        const autocomplete = new window.google.maps.places.Autocomplete(inputElement, {
          types: ["geocode"],
          componentRestrictions: { country: "IN" },
        });

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();
          handleSelect(input, place.formatted_address, place);
        });
      });
    };

    return () => {
      window.initAutocomplete = null;
    };
  }, []);

  return (
    <div className="container row" id="step2">
      <div className="col-md-4 col-lg-3">
        <div className="step-navigation" id="progressBar">
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Basic Details
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 1
            </small>
          </div>
          <div className={"step completed"}>
            <span className="step-number completed">✔</span>
            Property Profile
          </div>
          <div className="d-flex">
            <div className="connector completed"></div>
            <small
              style={{
                color: "#000000a2",
                marginLeft: "10px",
                fontSize: "13px",
              }}
            >
              Step 2
            </small>
          </div>
          <div className="step active">
            <span className="step-number active"></span>
            Photos
          </div>
        </div>
        <div className="mt-3" id="needHelp">
          <h2>Need Help ?</h2>
          <h6 style={{ opacity: "0.8" }}>Contact us Now</h6>
          <span>
            <FontAwesomeIcon icon={faPhone} /> +91-9316066832{" "}
          </span>
        </div>
      </div>
      <div className="col-md-6 col-lg-5" id="middle">
        <div
          role="button"
          id="backDiv"
          onClick={() => setCurrentStep(currentStep - 1)}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
          <span> Back</span>
        </div>
        
        <div className="mt-4" id="photosForm">
          <h5 id="photosFormHeader">Photos</h5>
          <p>Upload at least three photos.</p>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <div className="d-flex justify-content-center align-items-center">
              <div className="dragDropBox">
                <p className="dragDropText">Drag & drop photos here</p>
                <p>or</p>
                <Button color="primary">Browse Photos</Button>
              </div>
            </div>
          </div>
          <div className="row mt-4  step-4-photos">
            {photos.map((file, index) => {
              // console.log(file);
              return(
              <div className="col-md-3 mb-4" key={index}>
                <div className="photoPreview">
                <button
                    type="button"
                    className="deletePhotoButton"
                    onClick={() => handleDeletePhoto(index)}
                  >
                    <FontAwesomeIcon icon={faTimesCircle} style={{background : "white" , borderRadius : "50%"}}  />
                  </button>
                 {file&& <img
                    src={URL.createObjectURL(file)}
                    alt={`preview-${index}`}
                    className="img-fluid"
                  />}
                 
                </div>
              </div>
            
            )})}
          </div>
          {errors.some((error) => error.name === "photos") && (
            <p className="text-danger">
              {errors.find((error) => error.name === "photos").error}
            </p>
          )}
        </div>

        <div id="photosForm">
          <h5 id="photosFormHeader "  className="mt-2">Fill Address Details</h5>
          <div className="d-flex mt-3">
            <FormGroup className="w-50 pe-3">
              <Label for="houseno">
               Society Name
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="houseno"
                id="houseno"
                value={address.houseno}
                onChange={(e) => handleAddressChange("houseno", e.target.value)}
                onBlur={handleBlur}
                placeholder="Society Name"
              />
              {errors.some((error) => error.name === "houseno") && (
                <p className="text-danger">
                  {errors.find((error) => error.name === "houseno").error}
                </p>
              )}
            </FormGroup>
            <FormGroup className="w-50">
              <Label for="streetAddress">
                Street Address
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="streetAddress"
                id="streetAddress"
                value={address.streetAddress}
                onChange={(e) => handleAddressChange("streetAddress", e.target.value)}
                onBlur={handleBlur}
                placeholder="Street Address"
              />
              {errors.some((error) => error.name === "streetAddress") && (
                <p className="text-danger">
                  {errors.find((error) => error.name === "streetAddress").error}
                </p>
              )}
            </FormGroup>
          </div>
          <div className="d-flex">
            <FormGroup className="w-50 pe-3">
              <Label for="area">
                Area
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="area"
                id="area"
                value={address.area}
                onChange={(e) => handleAddressChange("area", e.target.value)}
                onBlur={handleBlur}
                placeholder="Area"
              />
              {errors.some((error) => error.name === "area") && (
                <p className="text-danger">
                  {errors.find((error) => error.name === "area").error}
                </p>
              )}
            </FormGroup>
            <FormGroup className="w-50">
              <Label for="pincode">
                Pincode
                <span style={{ color: "#f56a6a" }}>*</span>
              </Label>
              <Input
                type="text"
                name="pincode"
                id="pincode"
                value={address.pincode}
                onChange={(e) => handleAddressChange("pincode", e.target.value)}
                onBlur={handleBlur}
                placeholder="Pincode"
              />
              {errors.some((error) => error.name === "pincode") && (
                <p className="text-danger">
                  {errors.find((error) => error.name === "pincode").error}
                </p>
              )}
            </FormGroup>
          </div>
          <FormGroup className="w-50">
            <Label for="city">
              City
              <span style={{ color: "#f56a6a" }}>*</span>
            </Label>
            <Input
              type="text"
              name="city"
              id="city"
              value={address.city}
              onChange={(e) => handleAddressChange("city", e.target.value)}
              onBlur={handleBlur}
              placeholder="City"
            />
            {errors.some((error) => error.name === "city") && (
              <p className="text-danger">
                {errors.find((error) => error.name === "city").error}
              </p>
            )}
          </FormGroup>
        </div>
        <Button color="primary" onClick={handleSubmit} disabled={loading}>
          {loading ? <Spinner size="sm" /> : "Submit"}
        </Button>
      </div>
      {thankyoumodal && (
  <div className="thankyoumodal">
    <div className="modal-overlay">
      <div className="thankyouModal">
        <div className="modal-content">
          {success === true && (
            <div className="success-message">
              <FontAwesomeIcon icon={faCircleCheck} size="6x" />
              <h2>Property Successfully Listed!</h2>
              <p>Thank you for listing your property with us.</p>
              <button className="btn btn-success" onClick={thankyouModal}>
                Go to My Account
              </button>
            </div>
          )}
          {success === "Error" && (
            <div className="error-message">
              <FontAwesomeIcon icon={faCircleXmark} size="6x" />
              <h2>Error</h2>
              <p>There was an error while listing your property. Please try again later.</p>
              <button className="btn btn-danger" onClick={() => setThankyoumodal(false)}>
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Step4;
