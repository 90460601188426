import { lazy, useEffect } from "react";
import { Navigate } from "react-router-dom";
import UnverifiedProperties from "../views/ui/UnverifiedProperties.js";
import UserDetails from "../views/ui/UserDetails.js";
import Allproperties from "../views/ui/AllProperties.js";
import AllAdminDetails from "../views/ui/AllAdminDetails.js";
import AllOwnerDetails from "../views/ui/AllOwnerDetails.js"
import ListProperty from "../views/ui/ListProperty.js";
import ProtectedRoute from "../views/ui/ProtectedRoute.js";
import EditProperty from "../views/ui/EditProperty.jsx";
import AllReportedProperties from "../views/ui/AllReportedProperties.jsx";
import ReportedProperty from "../views/ui/ParticularReportedProperty.jsx";
import PaymentDetails from "../views/ui/PaymentDetails.jsx";
import ParticularPaymentDetails from "../views/ui/ParticularPaymentDetails.jsx";
import PendingPayments from "../views/ui/PendingPayments.jsx";
import FailedPayments from "../views/ui/FailedPayments.jsx";
import CancelledPayments from "../views/ui/CancelledPayments.jsx";
import Sales from "../views/ui/Sales.jsx";
import ParticularUnverifiedProperty from "../views/ui/ParticularUnverifiedProperty.js";
import RequestCallback from "../views/ui/RequestCallback.js"
import AllFlatSeekers from "../views/ui/AllFlatSeekers.js";
import CustomVerify from "../views/ui/CustomeVerify.js";
import AllRentedProperties from "../views/ui/AllRentedProperties.jsx";
import AllDeletedProperties from "../views/ui/DeletedProperties.jsx";
import AllRejectedProperties from "../views/ui/RejectedProperties.jsx";
import Cookies from "js-cookie";
import ParticularFlatProperty from "../views/ui/particularFlatSeekerProperty.jsx";
import ParticularFlatSeekerProperty from "../views/ui/particularFlatSeekerProperty.jsx";
/****Layouts*****/

const FullLayout = lazy(() => import("../layouts/FullLayout.js"));


/***** Pages ****/

const Starter = lazy(() => import("../views/Starter.js"));
const Login = lazy(() => import("../views/ui/Login"));
const Signup = lazy(() => import("../views/ui/Signup"));
const MyAccount = lazy(() => import("../views/ui/MyAccount.js"))
const ParticularProperty = lazy(() => import("../views/ui/ParticularProperty.js"))
const User = lazy(() => import("../views/ui/User.js"))
/*****Routes******/
// Function to wrap routes with the ProtectedRoute component
const wrapRoutesWithProtection = (routes, redirectTo) => {
  return routes.map((route) => {
    // Wrap each route with ProtectedRoute
    return {
      ...route,
      element: (
        <ProtectedRoute
          element={route.element}
          redirectTo={redirectTo}
          key={route.path} // Add a unique key to avoid React warnings
        />
      ),
    };
  });
};
const role = Cookies.get("role") || "Admin";



const adminRoutes = [
  { path: "/", element: <Navigate to="/starter" /> },
  { path: "/starter", exact: true, element: <Starter /> },
  { path: "/particular-property/:id", exact: true, element: <ParticularProperty /> },
  { path: "/particular-unverified-property/:id", exact: true, element: <ParticularUnverifiedProperty /> },
  { path: "/ParticularFlatseekerProperty/:id", exact: true, element: <ParticularFlatProperty /> },

 
  { path: "/unverifiedproperties", exact: true, element: <UnverifiedProperties /> },
  { path: "/all-properties", exact: true, element: <Allproperties /> },
  { path: "/all-rented-properties", exact: true, element: <AllRentedProperties /> },
  { path: "all-deleted-properties", exact: true, element: <AllDeletedProperties /> },
  { path: "/all-rejected-properties", exact: true, element: <AllRejectedProperties /> },
  { path: "/all-flatseekers", exact: true, element: <AllFlatSeekers /> },
  { path: "/all-admin-details", exact: true, element: <AllAdminDetails /> },
  { path: "/all-owner-details", exact: true, element: <AllOwnerDetails /> },
  { path: "/userdetails", exact: true, element: <UserDetails /> },
  { path: "/signup", exact: true, element: <Signup /> },
  { path: "/listproperty", exact: true, element: <ListProperty /> },
  { path: "/myaccount", exact: true, element: <MyAccount /> },
  { path: "/user", exact: true, element: <User /> },
  { path: "/edit-property", exact: true, element: <EditProperty /> },
  { path: "/all-reported-properties", exact: true, element: <AllReportedProperties /> },
  { path: "/reported/:id", exact: true, element: <ReportedProperty /> },
  { path: "/sales/failed-payments", exact: true, element: <FailedPayments /> },
  { path: "/sales/pending-payments", exact: true, element: <PendingPayments /> },
  { path: "/sales/cancelled-payments", exact: true, element: <CancelledPayments /> },
  { path: "/payment-details", exact: true, element: <PaymentDetails /> },
  { path: "/custom-verify", exact: true, element: <CustomVerify /> },
  { path: "/particular-payment-details", exact: true, element: <ParticularPaymentDetails /> },
  { path: "/sales", exact: true, element: <Sales /> },
  { path: "/request-callback", exact: true, element: <RequestCallback /> },
];

const moderatorRoutes = [
  { path: "/", element: <Navigate to="/starter" /> },
  { path: "/starter", exact: true, element: <Starter /> },
  { path: "/particular-property/:id", exact: true, element: <ParticularProperty /> },
  { path: "/particular-unverified-property/:id", exact: true, element: <ParticularUnverifiedProperty /> },
// import ParticularFlatseekerProperty from "./Components/ParticularFlatseekerProperty.jsx";
{ path: "/ParticularFlatseekerProperty/:id", exact: true, element: <ParticularFlatProperty  /> },
{ path: "/all-flatseekers", exact: true, element: <AllFlatSeekers /> },
// { path: "/all-owner-details", exact: true, element: <AllOwnerDetails /> },


  { path: "/unverifiedproperties", exact: true, element: <UnverifiedProperties /> },
  { path: "/all-properties", exact: true, element: <Allproperties /> },
  { path: "/all-rented-properties", exact: true, element: <AllRentedProperties /> },
  { path: "all-deleted-properties", exact: true, element: <AllDeletedProperties /> },
  { path: "/all-rejected-properties", exact: true, element: <AllRejectedProperties /> },
  { path: "/listproperty", exact: true, element: <ListProperty /> },
  { path: "/myaccount", exact: true, element: <MyAccount /> },
  { path: "/edit-property", exact: true, element: <EditProperty /> },
  { path: "/all-reported-properties", exact: true, element: <AllReportedProperties /> },
  { path: "/reported/:id", exact: true, element: <ReportedProperty /> },
 { path: "/custom-verify", exact: true, element: <CustomVerify /> },
  { path: "/particular-payment-details", exact: true, element: <ParticularPaymentDetails /> },
];
const ThemeRoutes = [
  {
    path: "/",
    element: <FullLayout />,
    children: role === "Admin"
    ? wrapRoutesWithProtection(adminRoutes, '/login')
    : role ==="Moderator" ? wrapRoutesWithProtection(moderatorRoutes, '/starter') : wrapRoutesWithProtection(moderatorRoutes, '/login') ,
},
  { path: "/login", element: <Login /> },
];

export default ThemeRoutes;
