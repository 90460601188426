import { useState, useEffect } from "react";
import axios from "../../api/axios";
import { useLocation } from "react-router-dom";
import { Typography, Container, Paper, Grid } from "@mui/material";

const ParticularPaymentDetails = () => {
  const [data, setData] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get("id");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`/user/plan-details/${paramValue}/dkkdnie/cjdbcuh`);
        setData(res.data);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <Container maxWidth="md">
      <Paper elevation={3} style={{ padding: "20px", margin: "20px" }}>
        {data ? (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h4">{data.name}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">
                User ID: {data.userId}
              </Typography>
              <Typography variant="subtitle1">Name : {data.name}</Typography>
              <Typography variant="subtitle1">Number: {data.number}</Typography>
              <Typography variant="subtitle1">Plan: {data.plan}</Typography>
              <Typography variant="subtitle1">Amount: {data.amount}</Typography>
              <Typography variant="subtitle1">
                Purchased On: {data.purchased_On}
              </Typography>
              <Typography variant="subtitle1">
                Expired On: {data.expired_On}
              </Typography>
              <Typography variant="subtitle1">
                Order ID: {data.orderId}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1">Credit: {data.credit}</Typography>
              <Typography variant="subtitle1">
                Used Credit: {data.usedCredit}
              </Typography>
              <Typography variant="subtitle1">
                Remaining Credit: {data.remainingCredit}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                <b>Requirements</b>:
              </Typography>
              <Typography variant="subtitle1">
                Budget: {data.requirement ? data.requirement.budget : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Location: {data.requirement ? data.requirement.location : "-"}
              </Typography>
              <Typography variant="subtitle1">
                No. of People:{" "}
                {data.requirement ? data.requirement.noOfPeople : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Description:{" "}
                {data.requirement ? data.requirement.description : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Created On:{" "}
                {data.requirement ? data.requirement.created_on : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                <b>Bank Details:</b>
              </Typography>
              <Typography variant="subtitle1">
                Bane Ref no:{" "}
                {data.bankDetails ? data.bankDetails.bank_ref_no : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Payment Mode:{" "}
                {data.bankDetails ? data.bankDetails.payment_mode : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Card Name: {data.bankDetails ? data.bankDetails.card_name : "-"}
              </Typography>
              <Typography variant="subtitle1">
                Tracking Id:{" "}
                {data.bankDetails ? data.bankDetails.tracking_id : "-"}
              </Typography>
            </Grid>

            {data.refundDetails && (
              <Grid item xs={12}>
                <Typography variant="h6">
                  <b>Refund Details:</b>
                </Typography>
                <Typography variant="subtitle1">
                  Refund Reason:{" "}
                  {data.refundDetails ? data.refundDetails.refundReason : "-"}
                </Typography>
                <Typography variant="subtitle1">
                  Refund App:{" "}
                  {data.refundDetails ? data.refundDetails.refundApp : "-"}
                </Typography>
                <Typography variant="subtitle1">
                  Refund Date:{" "}
                  {data.refundDetails ? data.refundDetails.refundDate : "-"}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography variant="body1">Loading data...</Typography>
        )}
      </Paper>
    </Container>
  );
};

export default ParticularPaymentDetails;
