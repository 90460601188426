import "./sales.css";
import React, { useEffect, useState } from "react";
import axios from "../../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight, faClose } from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Card,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import DatePicker from "react-datepicker";

const adminId = Cookies.get("id");

const Sales = () => {
  const today = new Date();
  const thirtyDaysAgo = new Date(today.getTime() - (30 * 24 * 60 * 60 * 1000));
  const [superpassword, setSuperpassword] = useState("1");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [currData, setCurrData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [success, setSuccess] = useState(true);
  const [refund, setRefund] = useState(false);
  const [expired, setExpired] = useState(false);
  const [sales, setSales] = useState(false);
  const [orderId, setOrderId] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [perPage] = useState(10); // Number of items per page
  const [totalPages, setTotalPages] = useState();
  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(today);

  const superAdminIds = [
    "6593f8cd8f454513dc450ba0",
    "65853ba85915247c5ab577bc",
  ];

  const isSuperAdmin = (adminId) => {
    return superAdminIds.includes(adminId);
  };

  useEffect(() => {
    // This effect will run when the component mounts and anytime adminId changes
    if (!isSuperAdmin(adminId)) {
      setShowModal(false);
    }
  }, [adminId]);

  const [refundData, setRefundData] = useState({
    reason: "",
    application: "",
  });

  const fetchData = async (paymentStatus, page) => {
    try {
      let stDate;
    let edDate;

    if (startDate) {
      const year = startDate.getFullYear();
      const month = String(startDate.getMonth() + 1).padStart(2, "0");
      const day = String(startDate.getDate()).padStart(2, "0");
      stDate = `${year}-${month}-${day}`;
    }

    if (endDate) {
      const year1 = endDate.getFullYear();
      const month1 = String(endDate.getMonth() + 1).padStart(2, "0");
      const day1 = String(endDate.getDate()).padStart(2, "0");
      edDate = `${year1}-${month1}-${day1}`;
    }
      const res = await axios.post(
        `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/citynect-ckdfjij/cisjdhniw/payment-details/edjkfbhdb?paymentStatus=${paymentStatus}&page=${page}&size=10&startDate=${stDate ? stDate : ""}&endDate=${edDate ? edDate : ""}`
      );
      setData(res.data.data);
      setTotal(res.data.totalItems);
      setTotalPages(res.data.totalPages);
    } catch (error) {
      console.log(error);
      setData([]);
    }
  };

  useEffect(() => {
    let paymentStatus = "Success"; // Default status
    if (refund) paymentStatus = "Refund";
    if (expired) paymentStatus = "Expired";
    if (sales) paymentStatus = "Pending"; // Assuming 'Pending' for sales
    fetchData(paymentStatus, currentPage);
  }, [success, refund, expired, sales, currentPage, startDate, endDate]);

  useEffect(() => {
    const filteredData = data && data.filter((item) => {
      if (success) return item.paymentStatus === "Success";
      if (refund) return item.paymentStatus === "Refund";
      if (expired) return item.paymentStatus === "Expired";
      if (sales) return ["Pending", "Failed", "Cancelled"].includes(item.paymentStatus);
      return true;
    });

    setFilterData(filteredData);
  }, [data, success, refund, expired, sales, currentPage, perPage]);

  const nextPage = () => {
    setCurrentPage((prevPage) =>
      Math.min(prevPage + 1, Math.ceil(total / perPage))
    );
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleFilter = (filter) => {
    setSuccess(filter === "Success");
    setRefund(filter === "Refund");
    setExpired(filter === "Expired");
    setSales(filter === "Sales");
    setCurrentPage(0); // Reset current page when changing filters
  };

  const handleChange = (e) => {
    setRefundData({
      ...refundData,
      [e.target.name]: e.target.value,
    });
  };

  const handleClick = async (event, orderId) => {
    event.preventDefault();
    const button = event.currentTarget;
    if (refundData.reason === "" || refundData.application === "") {
      alert("Please fill out all fields!");
      return;
    }
    button.disabled = true;

    try {
      await axios.post(
        `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/citynect-kfjhed8fu/api/ijfheifuheb8u/refund-order`,
        {
          orderId: orderId,
          refundDetails: {
            adminId: adminId,
            refundReason: refundData.reason,
            refundFrom: refundData.application,
          },
        }
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSalesClick = async (event, orderId) => {
    const button = event.currentTarget;
    button.disabled = true;

    try {
      await axios.get(
        `/admin/citynect-only/protected/v1/eijfnirfrfb/citynect-dfijehdifwh/njfnef/callstatus-update/${orderId}`
      );
      window.location.reload();
    } catch (error) {
      console.log(error);
    }
  };

  const handleRefundButtonClick = (orderId) => {
    setOrderId(orderId);
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    if (superpassword === "1") {
      setShowModal(false);
    } else {
      setError("Invalid Password");
    }
  };

  return (
    <Card className={showModal ? "blur-background" : ""}>
      <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
        <i className="bi bi-people-fill me-2"></i>
        {success && "Success"}
        {refund && "Refunded"}
        {expired && "Expired"}
        {sales && "Pending Checkouts"}
      </CardTitle>
      <div className="App mb-5">
        <Modal isOpen={showModal} backdrop="static" keyboard={false}>
          <ModalHeader>
            <span>Password Verification </span>
            <Link to="/starter" style={{ paddingLeft: "175px" }}>
              <Button>
                <FontAwesomeIcon icon={faClose} />
              </Button>
            </Link>
          </ModalHeader>

          <ModalBody>
            <Form onSubmit={handleModalSubmit}>
              <FormGroup>
                <Label for="superpassword">Password:</Label>
                <Input
                  type="password"
                  id="superpassword"
                  required
                  value={superpassword}
                  onChange={(e) => setSuperpassword(e.target.value)}
                />
              </FormGroup>
              {error && <p className="text-danger">{error}</p>}
              <Button type="submit">Continue</Button>
            </Form>{" "}
          </ModalBody>
        </Modal>

        <br />
 <div className="buttonbox">
 <button
          onClick={() => handleFilter("Success")}
          style={{
            backgroundColor: success ? "#287dfd" : "initial",
            color: success ? "white" : "initial",
            marginRight: "8px",
            height: "40px",
            borderRadius: "10px",
            paddingLeft : "10px"
          }}
        >
          Success
        </button>

        <button
          onClick={() => handleFilter("Refund")}
          style={{
            backgroundColor: refund ? "#287dfd" : "initial",
            color: refund ? "white" : "initial",
            marginRight: "8px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          Refunded
        </button>

        <button
          onClick={() => handleFilter("Expired")}
          style={{
            backgroundColor: expired ? "#287dfd" : "initial",
            color: expired ? "white" : "initial",
            marginRight: "8px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          Expired
        </button>

        <button
          onClick={() => handleFilter("Sales")}
          style={{
            backgroundColor: sales ? "#287dfd" : "initial",
            color: sales ? "white" : "initial",
            marginRight: "8px",
            height: "40px",
            borderRadius: "10px",
          }}
        >
          Pending Checkouts
        </button>
 </div>
        

        <br />
        <div className="salebox">
        <div className="date-picker-container  sales-date">
          
          <p>Start Date : </p>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            dateFormat="dd-MM-yyyy" // You can customize the date format
            isClearable // Add a clear button
            showYearDropdown // Show year dropdown
            scrollableYearDropdown // Make year dropdown scrollable
            placeholderText="Select a Date"
          />
          </div>
      <div className="date-picker-container sales-date">

          <p>End Date : </p>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            dateFormat="dd-MM-yyyy" // You can customize the date format
            isClearable // Add a clear button
            showYearDropdown // Show year dropdown
            scrollableYearDropdown // Make year dropdown scrollable
            placeholderText="Select a Date"
          />
        </div>
        </div>
       
        <br />

        <table>
          <thead>
            <tr>
              <th>Index No.</th>
              <th>Name</th>
              <th>Number</th>
              <th>Amount</th>
              <th>Plan</th>
              <th>Order ID</th>
              <th>Status</th>
              <th>{refund ? "Refunded On" : "Created On"}</th>
              {sales && <th>Call Status</th>}
            </tr>
          </thead>
          <tbody>
            {data && data.length !==0 && data.map((item, index) => (
              <tr key={item.id}>
                <td>{(currentPage) * perPage + index + 1}</td>
                <td>{item.name ? item.name : "-"}</td>
                <td>{item.number ? item.number : "-"}</td>
                <td>{item.amount ? item.amount : "-"}</td>
                <td>{item.plan ? item.plan : "-"}</td>
                <td>{item.orderId ? item.orderId : "-"}</td>
                <td
  style={{
    color: success || refund || expired ? "green" : "red",
  }}
>
  {item.paymentStatus ? item.paymentStatus : "-"}
  {success && (
    <div>
      {/* Assuming item.date is your date field */}
      {item.date ? new Date(item.date).toLocaleDateString("en-GB") : ""}
      
      <Button
        id={`PopoverLegacy${index}`} // Use index to create unique IDs for each button
        type="button"
        onClick={() => handleRefundButtonClick(item.orderId)}
      >
        Refund
      </Button>
      <UncontrolledPopover
        placement="bottom"
        target={`PopoverLegacy${index}`} // Use index to target the correct popover
        trigger="legacy"
      >
        <PopoverHeader>Refund</PopoverHeader>
        <PopoverBody>
          <label>Reason: </label>
          <input name="reason" onChange={handleChange} />
          <br />
          <br />
          <label>Application: </label>
          <input
            style={{ width: "60%" }}
            name="application"
            onChange={handleChange}
          />
          <br />
          <br />
          <Button onClick={(e) => handleClick(e, item.orderId)}>
            Submit
          </Button>
        </PopoverBody>
      </UncontrolledPopover>
    </div>
  )}
</td>

<td>{item.paymentDetails?.trans_date ? new Date(item.paymentDetails.trans_date).toLocaleDateString("en-GB") : new Date(item.createdOn).toLocaleDateString("en-GB")}</td>
             
                {sales && (
                  <td>
                    
                    {item.callStatus ? (
                      item.callStatus === "Done" ? (
                        <>Done</>
                      ) : (
                        <Button
                          color="primary"
                          onClick={(e) => handleSalesClick(e, item.orderId)}
                        >
                          Update
                        </Button>
                      )
                    ) : (
                      "-"
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {/* Pagination controls */}
      <div className="paginationBox" style={{ marginBottom: "30px" }}>
        <Button onClick={prevPage} className="paginationBtn" disabled={currentPage === 0}>
          <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
        </Button>
        <span>{currentPage+1}</span>
        <Button
          onClick={nextPage} className="paginationBtn"
          disabled={currentPage === totalPages-1}
        >
          Next <FontAwesomeIcon icon={faArrowRight} />
        </Button>
      </div>
    </Card>
  );
};

export default Sales;
