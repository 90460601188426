import React, { useState } from 'react';
import { Carousel, CarouselItem, CarouselControl } from 'reactstrap';

const CustomCarousel = ({ mediaUrls }) => {
  const [animating, setAnimating] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const next = (length) => {
    if (animating) return;
    const nextIndex = activeIndex === length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = (length) => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const handleImageError = (event) => {
    event.target.src = "https://via.placeholder.com/300"; // Fallback image URL
  };

  const isVideo = (url) => {
    const videoFormats = ['mp4', 'webm', 'ogg'];
    const extension = url.split('.').pop().toLowerCase();
    return videoFormats.includes(extension);
  };

  return (
    <Carousel
      activeIndex={activeIndex}
      next={() => {
        next(mediaUrls.length);
      }}
      previous={() => {
        previous(mediaUrls.length);
      }}
      id="ImageBox"
      interval={false}
    >
      {(mediaUrls || []).map((item, index) => (
        <CarouselItem
          onExiting={() => setAnimating(true)}
          onExited={() => setAnimating(false)}
          key={index}
        >
          {/* {console.log("Loading URL:", item)} */}
          {isVideo(item) ? (
            <video
              src={item}
              controls
              alt="propertyVideo"
              id="CarouselImage"
              
              className="rounded-2"
            />
          ) : (
            <img
              src={item}
              alt="propertyImage"
              id="CarouselImage"
              className="rounded-2"
              onError={handleImageError}
            />
          )}
        </CarouselItem>
      ))}

      <CarouselControl
        direction="prev"
        directionText="Previous"
        onClickHandler={() => {
          previous(mediaUrls.length);
        }}
      />
      <CarouselControl
        direction="next"
        directionText="Next"
        onClickHandler={() => {
          next(mediaUrls.length);
        }}
      />
    </Carousel>
  );
};

export default CustomCarousel;
