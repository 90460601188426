import "./sales.css";
import { useEffect, useState } from "react";
import axios from "../../api/axios";
import { Button } from "reactstrap";

const FailedPayments = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios("/api/v1/paymentGateway/payment-details");
        // setData(res.data);
        const filteredData = res.data.filter(
          (item) =>
            item.paymentStatus === "Failed"
        );
        setData(filteredData);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleClick = async (event, orderId) => {
    const button = event.currentTarget;
    button.disabled = true;
    alert(orderId);

    try {
      axios.get(`/api/v1/paymentGateway/callstatus-update/${orderId}`);
    } catch (error) {
      // console.log(error);
    }
  }

  return (
    <div className="App">
      <h1>Sales - Failed Payments</h1>
      <br />
      <br />
      <table>
        <thead>
          <tr>
            <th>Index No.</th>
            <th>Customer ID</th>
            <th>Amount</th>
            <th>Plan</th>
            <th>Order ID</th>
            <th>Status</th>
            <th>Created On</th>
            <th>Call Status</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>{item.customerId ? item.customerId : "-"}</td>
              <td>{item.amount ? item.amount : "-"}</td>
              <td>{item.plan ? item.plan : "-"}</td>
              <td>{item.orderId ? item.orderId : "-"}</td>
              <td style={{ color: "red" }}>
                {item.paymentStatus ? item.paymentStatus : "-"}
              </td>
              <td>{item.createdOn ? new Date(item.createdOn).toLocaleDateString('en-GB') : "-"}</td>

              <td>{item.callStatus ? (item.callStatus==='Done' ? <>Yes</> : <>No</>) : "-"}</td>
              <td>{item.callStatus ? (item.callStatus==='Done' ? <>Done</> : <Button color="primary" onClick={(e) => handleClick(e, item.orderId)}>Update</Button>) : "-"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FailedPayments;
