import "./UnverifiedProperties.css";
import React, { useEffect, useState } from "react";
import CustomCarousel from "../../components/CustomCarousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faArrowRight,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardTitle,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import Cookies from "js-cookie";

const UnverifiedProperties = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState(properties);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState("");
  const [propertyIdToDelete, setPropertyIdToDelete] = useState(null);
  const adminId = Cookies.get("id");
  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/hfuyfg8uefih/fejifhebgyeiu/Unverified-properties/fedfiebhfud?page=0&size=10`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setProperties(data.content);
        setFilteredProperties(data.content);
      })
    // .catch((err) => console.log(err));
  }, []);
  const navigate = useNavigate();

  const handleEditPropertyButton = (id) => {
    navigate(`/edit-property?id=${id}`);
  };

  const [currentPage, setCurrentPage] = useState(1);
  let totalPages = 1;
  let visibleData = filteredProperties;
  if (filteredProperties.length && filteredProperties.length > 10) {
    totalPages = Math.ceil(filteredProperties.length / 10);
    const startIndex = (currentPage - 1) * 10;
    const endIndex = startIndex + 10;
    visibleData = filteredProperties.slice(startIndex, endIndex);
  }
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const openModal = (propertyId) => {
    // console.log("Opening modal for propertyId:", propertyId);
    setPropertyIdToDelete(propertyId);
    setModalOpen(true);
  };

  // Function to close the modal
  const closeModal = () => {
    // console.log("Closing modal");
    setModalOpen(false);
  };

  const handleDeleteProperty = (propertyId) => {
    // Construct the URL with the adminId and propertyId
    const url = `${process.env.REACT_APP_API_IP}/admin/citynect-only/protected/v1/eijfnirfrfb/reject-property/${adminId}/dcjiebdu/dejbdyw`;

    // Prepare the request body
    const requestBody = {
      id: propertyId,
      rejectReason: deleteReason,
    };

    // Send the POST request
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => {
        // console.log("Response status:", response.status);
        // Handle the response here
        if (response.ok) {
          // console.log("Property deleted successfully");
          // You can add code here to handle success, e.g., removing the property from the list
          // Reload the page
          window.location.reload();
        } else {
          console.error("Error deleting property");
          // Handle errors here
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle errors here
      });

    // Close the modal after submitting
    closeModal();
  };
  return (
    <Card>
      <CardTitle tag="h4" className="text-center border-bottom p-3 mb-0">
        <i className="bi bi-house-exclamation me-2"></i>
        Unverified Properties
      </CardTitle>
      <CardBody>
        {visibleData.length > 0 ? (
          <div className="">
            <div className="header"></div>
            <div className="row justify-content-center">
              {/* Main Contain */}
              <div className="mainContain py-2 col-md-12">
                <div className="mb-3 " style={{ maxWidth: "100%" }}>
                  {visibleData.map((property, index) => (
                    <div
                      className="row shadow-sm no-gutters rounded-2"
                      key={index}
                      id="propertyCard"
                    >
                      <div className="col-md-4 p-0">
                        <div id="GenderBox">
                          <span className="">{property.memberedAllowed}</span>
                        </div>
                        <CustomCarousel mediaUrls={property.photoUrls} />
                      </div>
                      <div className="col-md-6" id="propertyCard-body">
                        <Link
                          className="text-decoration-none text-dark"
                          to={`/particular-unverified-property/${property.id}`}
                        >
                          <div id="card-Heading">{property.title}</div>
                          <div id="card-location" className="row">
                            <div id="" className="col">
                              <FontAwesomeIcon
                                className="me-2 grey"
                                icon={faLocationDot}
                              />{" "}
                              {property.address && property.address.area}{" "}
                              {property.address && property.address.city}{" "}

                              {property.address && property.address.pincode}
                            </div>
                            <div className="LaptopHide col">
                              Type : {property.furnishedType}
                            </div>
                          </div>
                          <div id="card-Details" className="row">
                            <div id="Details" className="col">
                              <span className="grey">Available For :</span>{" "}
                              {property.memberedAllowed}
                            </div>
                            <div id="Details" className="col">
                              <span className="grey">Property Type :</span>{" "}
                              {property.subtype.length > 12
                                ? `${property.subtype.substring(0, 12)}..`
                                : property.subtype}{" "}
                            </div>
                          </div>
                          <div id="card-Details" className="row">
                            <div id="Details" className="col">
                              <span className="grey">Property Size :</span>{" "}
                              {property.bedroom} BHK
                            </div>
                            <div id="Details" className="col">
                              <span className="grey">Status :</span> Available
                            </div>
                          </div>
                          <div id="emnities" className=" mobileAndTab-hide">
                            {property.amenities.includes("electricity") && (
                              <img
                                className="amenities-size"
                                src="/emenities/Electricity.png"
                                alt="Amenities"
                              />
                            )}
                            {property.amenities.includes("balcony") && (

                              <img
                                className=" amenities-size"
                                src="/emenities/balcony.png"
                                alt="Amenities"
                              />

                            )}
                            {property.amenities.includes("cctv") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/CCTV.png"
                                alt="Amenities"
                              />
                            )}
                            {property.amenities.includes("cooking") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Cooking.png"
                                alt="Amenities"
                              />
                            )}
                            {property.amenities.includes("24*7-water") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Water.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                            {property.amenities.includes("house-keeping") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Housekeeping.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                            {property.amenities.includes(
                              "2-wheeler-parking"
                            ) && (
                                <img
                                  className=" amenities-size"
                                  src="/emenities/Parking.png"
                                  alt="Amenities"
                                  style={{ color: "red" }}
                                />
                              )}
                            {property.amenities.includes("fans") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Fen.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                            {property.amenities.includes("fridge") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Fridge.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                            {property.amenities.includes("laundry") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Laundry.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                            {property.amenities.includes("security") && (
                              <img
                                className=" amenities-size"
                                src="/emenities/Security.png"
                                alt="Amenities"
                                style={{ color: "red" }}
                              />
                            )}
                          </div>
                          <div id="rentBox" className="row">
                            <div className="col p-0 mobileAndTab-hide">
                              <b
                                style={{
                                  fontSize: "23px",
                                  color: "rgba(0, 0, 0, 0.6)",
                                }}
                              >
                                {/* {property.rent
                                  ? `₹ ${property.rent}`
                                  : `₹ ${property.rentEachHead}`} */}
                                {property.type === "private flat"
                                  ? `₹ ${property.totalFlatRent}`
                                  : property.sharingType?.singleRent
                                    ? `₹ ${property.sharingType.singleRent}`
                                    : property.sharingType?.doubleRent
                                      ? `₹ ${property.sharingType.doubleRent}`
                                      : property.sharingType?.tripleRent
                                        ? `₹ ${property.sharingType.tripleRent}`
                                        : property.rentEachHead
                                          ? `₹ ${property.rentEachHead}`
                                          : "null"}
                              </b>{" "}
                              <span className="grey">/per month</span>
                            </div>
                            <div
                              className="col p-0 mobileAndTab-hide "
                              style={{ color: "rgba(0, 0, 0, 0.7)" }}
                            >
                              {" "}
                              <span className="grey">Deposit </span>:{" "}
                              {property.deposit} Rent{" "}
                            </div>
                          </div>
                        </Link>
                        <div
                          className="col p-0"
                          style={{ color: "rgba(0, 0, 0, 0.7)" }}
                        >
                          <span className="grey">Property Id </span>:{" "}
                          {property.id}
                        </div>
                      </div>
                      <div className="col-md-2 rounded-4 " id="card-ButtonBox">
                        <div className="ownerBox">
                          <span>Owner :</span>
                          <br /> {property.name}
                        </div>
                        <div className="LaptopHide">
                          <b
                            style={{
                              marginLeft: "2px",
                              fontSize: "20px",
                              color: "rgba(0, 0, 0, 0.6)",
                            }}
                          >
                            {property.type === "private flat"
                              ? `₹ ${property.totalFlatRent}`
                              : property.sharingType?.singleRent
                                ? `₹ ${property.sharingType.singleRent}`
                                : property.sharingType?.doubleRent
                                  ? `₹ ${property.sharingType.doubleRent}`
                                  : property.sharingType?.tripleRent
                                    ? `₹ ${property.sharingType.tripleRent}`
                                    : property.rentEachHead
                                      ? `₹ ${property.rentEachHead}`
                                      : "null"}
                          </b>{" "}
                          <span className="grey" style={{ fontSize: "12px" }}>
                            /per month
                          </span>
                        </div>
                        {/* "Delete Property" button */}
                        <Button
                          id="contact-owner"
                          onClick={() => openModal(property.id)} // Assuming property.id holds the ID of the property you want to delete
                        >
                          Reject Property
                        </Button>
                        <Button
                          // className=" mobileAndTab-hide"
                          id="contact-owner"
                        
                          onClick={() => handleEditPropertyButton(property.id)}
                        >
                          Edit Property
                        </Button>
                      </div>

                    </div>
                  ))}
                  {filteredProperties.length > 10 && (
                    <div className="paginationBox">
                      <Button
                        className="paginationBtn"
                        onClick={goToPreviousPage}
                      >
                        {" "}
                        <FontAwesomeIcon icon={faArrowLeft} /> Previous{" "}
                      </Button>
                      <Button className="paginationBtn" onClick={goToNextPage}>
                        Next <FontAwesomeIcon icon={faArrowRight} />
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* Modal for delete reason input */}
            <Modal isOpen={modalOpen} toggle={closeModal}>
              <ModalHeader toggle={closeModal}>Enter Reject Reason</ModalHeader>
              <ModalBody>
                <Input
                  type="text"
                  placeholder="Reject Reason"
                  value={deleteReason}
                  onChange={(e) => setDeleteReason(e.target.value)}
                />
                <Button
                  color="danger"
                  onClick={() => handleDeleteProperty(propertyIdToDelete)}
                  className="mt-3"
                >
                  Confirm Reject
                </Button>
              </ModalBody>
            </Modal>
          </div>
        ) : (
          <center style={{ marginTop: "30px" }}>
            <h2>No Unverified Properties To Show</h2>
          </center>
        )}
      </CardBody>
    </Card>
  );
};

export default UnverifiedProperties;
